import React, { memo, useEffect, useState, useRef } from 'react';
import axios from 'axios';
import CardHeaderNoCrm from './CardHeaderNoCrm';
import CardLeftDetailsNoCrm from './CardLeftDetailsNoCrm';
import CardRightFeaturesNoCrm from './CardRightFeaturesNoCrm';

const ObjectDetailsNoCrm = memo(({ subject }) => {
  const [extendedData, setExtendedData] = useState(null);
  const [hasLoaded, setHasLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const containerRef = useRef(null);

  // Функция для подгрузки данных сразу с двух эндпоинтов
  const fetchExtendedData = async () => {
    setIsLoading(true);
    try {
      // Делаем запросы параллельно
      const [respExtended, respExtendedAbout] = await Promise.all([
        axios.get(
          `${process.env.REACT_APP_API_URL}/dashboard/api/public/subject/${subject.id}/extended/`
        ),
        axios.get(
          `${process.env.REACT_APP_API_URL}/dashboard/api/public/subject/${subject.id}/extended_about/`
        ),
      ]);

      // Объединяем данные в единый объект
      const mergedData = {
        ...respExtended.data,
        ...respExtendedAbout.data,
      };
      setExtendedData(mergedData);
    } catch (error) {
      console.error('Ошибка при загрузке расширенных данных:', error);
    } finally {
      setIsLoading(false);
      setHasLoaded(true);
    }
  };

  // Используем IntersectionObserver для ленивой подгрузки
  useEffect(() => {
    const observerOptions = {
      root: null,
      rootMargin: '0px',
      threshold: 0.2,
    };

    const observerCallback = (entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting && !hasLoaded) {
          // Когда карточка появляется в зоне видимости впервые — подгружаем данные
          fetchExtendedData();
          observer.unobserve(entry.target);
        }
      });
    };

    const observer = new IntersectionObserver(observerCallback, observerOptions);
    if (containerRef.current) {
      observer.observe(containerRef.current);
    }
    return () => {
      if (containerRef.current) {
        observer.unobserve(containerRef.current);
      }
    };
  }, [hasLoaded, subject.id]);

  // Если extendedData уже загружено, будем брать данные оттуда,
  // иначе используем короткий объект subject
  const subjectData = extendedData || subject;

  // Ниже оставим ваш код для формирования `features` (пример).
  // Если в extendedData появятся новые ключи, можно дописать их.
  const hasPhotos = subjectData?.has_photos || false;
  const priceChangeCount = subjectData?.history_counts?.price_change_count || 0;
  const targetChangeCount = subjectData?.history_counts?.target_change_count || 0;
  const duplicatesCount = subjectData?.duplicates_count || 0;
  const adLink = 'https://example.com/advert';

  const features = [
    { label: 'Фотографии', value: hasPhotos, icon: <span role="img" aria-label="photo">🖼️</span> },
    { label: 'Изменения цен', value: priceChangeCount, icon: <span role="img" aria-label="price">💲</span> },
    { label: 'Исторических записей всего', value: targetChangeCount, icon: <span role="img" aria-label="promotion">⚡</span> },
    { label: 'Дубликатов найдено', value: duplicatesCount, icon: <span role="img" aria-label="duplicates">🏠</span> },
    { label: 'Записей изменения цены', value: adLink, icon: <span role="img" aria-label="link">🔗</span> },
  ];

  const isTruthy = val => {
    if (typeof val === 'boolean') return val;
    if (typeof val === 'number') return val > 0;
    if (typeof val === 'string') return val.trim() !== '';
    return false;
  };

  return (
    <div
      ref={containerRef}
      className="w-[95%] mx-auto relative mb-6 rounded-xl overflow-hidden shadow-md ring-1 ring-slate-200 transition-transform hover:scale-105 bg-white"
    >
      {/* CardHeaderNoCrm — тоже получит расширенные данные, если они уже есть */}
      <CardHeaderNoCrm subject={subjectData} />
      <div className="px-6 py-4 bg-gradient-to-br from-white to-slate-50">
        {/* Плашка при первой загрузке extendedData */}
        {isLoading && !extendedData && (
          <p className="text-center text-sm text-gray-500 animate-pulse">
            Загрузка дополнительных данных...
          </p>
        )}
        <div className="flex flex-col md:flex-row md:gap-8">
          {/* Левая колонка: фотография (пример, можно заменить) */}
          <div className="flex-shrink-0">
            <img
              src="https://dummyimage.com/250x250/cccccc/000000.png&text=Фото"
              alt="Фото"
              className="w-[250px] h-[250px] object-cover rounded-md"
            />
          </div>
          {/* Средняя колонка: подробности объекта */}
          <CardLeftDetailsNoCrm subject={subjectData} />
          {/* Правая колонка: особенности/фичи объекта */}
          <CardRightFeaturesNoCrm features={features} />
        </div>
      </div>
    </div>
  );
});

export default ObjectDetailsNoCrm;
