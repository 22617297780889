// src/components/ParserSelect.js
import React, { useState } from "react";
import StartProcess from "./start/StartProcess";
import StartImgAPIProcess from "./start/StartImgAPIProcess";
import StartImgDownloads from "./start/Start_img_downloads";
import EnrichAddresses from "./start/EnrichAddresses";
import StartNewsParser from "./start/StartNewsParser";
import { Row, Col, Card, Button } from "antd";
import { 
  DatabaseOutlined, 
  EnvironmentOutlined, 
  CalendarOutlined, 
  PictureOutlined,
  FileTextOutlined
} from "@ant-design/icons";

/**
 * Компонент, позволяющий выбрать тип парсинга:
 * - Общий сбор
 * - Обогащение адресов
 * - Год постройки
 * - Изображения
 */
const ParserSelect = ({ user }) => {
  const [parseType, setParseType] = useState(null);

  const handleBack = () => {
    setParseType(null);
  };

  // Стиль контейнера с градиентным фоном, скруглёнными углами и тенью
  const containerStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "40px",
    padding: "30px",
    background: "linear-gradient(135deg, #f6f9fc, #e9eff5)",
    borderRadius: "12px",
    boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
  };

  // Функция для генерации стиля карточки с заданным фоновым цветом
  const cardStyle = (bgColor) => ({
    width: 150,
    textAlign: "center",
    backgroundColor: bgColor,
    borderRadius: "8px",
    boxShadow: "0 2px 8px rgba(0, 0, 0, 0.15)",
    cursor: "pointer",
    transition: "transform 0.2s, box-shadow 0.2s",
  });

  // Если выбран режим "Общий сбор"
  if (parseType === "common") {
    return (
      <div style={containerStyle}>
        <h2 style={{ color: "#333" }}>Общий сбор</h2>
        <StartProcess user={user} />
        <Button type="primary" onClick={handleBack} style={{ marginTop: 16 }}>
          Вернуться к выбору типа
        </Button>
      </div>
    );
  }

  // Если выбран режим "Обогащение адресов"
  if (parseType === "enrich") {
    return <EnrichAddresses user={user} onBack={handleBack} />;
  }


  if (parseType === "images") {
    return (
      <div style={containerStyle}>
        <StartImgAPIProcess user={user} />
        <Button type="primary" onClick={handleBack} style={{ marginTop: 14 }}>
          Вернуться к выбору
        </Button>
      </div>
    );
  }
  if (parseType === "images_download") {
    return (
      <div style={containerStyle}>
        <StartImgDownloads user={user} />
        <Button type="primary" onClick={handleBack} style={{ marginTop: 14 }}>
          Вернуться к выбору
        </Button>
      </div>
    );
  }
  if (parseType === "news") {
    return (
      <div style={containerStyle}>
        <StartNewsParser user={user} />
        <Button type="primary" onClick={handleBack} style={{ marginTop: 14 }}>
          Вернуться к выбору
        </Button>
      </div>
    );
  }

  // Если режим не выбран, показываем меню выбора с яркими карточками
  return (
    <div style={containerStyle}>
      {/* <h2 style={{ marginBottom: "14px", color: "#555" }}>Выберите тип парсинга</h2> */}
      <Row gutter={16} justify="center">
        <Col>
          <Card
            hoverable
            style={cardStyle("#ffecb3")} // Светло-жёлтый фон
            onClick={() => setParseType("common")}
          >
            <DatabaseOutlined style={{ fontSize: "36px", color: "#f57f17", marginBottom: "10px" }} />
            <div style={{ fontWeight: "bold", color: "#6d4c41" }}>Общий сбор</div>
          </Card>
        </Col>

        <Col>
          <Card
            hoverable
            style={cardStyle("#c8e6c9")} // Светло-зелёный фон
            onClick={() => setParseType("enrich")}
          >
            <EnvironmentOutlined style={{ fontSize: "36px", color: "#2e7d32", marginBottom: "10px" }} />
            <div style={{ fontWeight: "bold", color: "#1b5e20" }}>DaData</div>
          </Card>
        </Col>

        <Col>
          <Card
            hoverable
            style={cardStyle("#f8bbd0")} // Светло-розовый фон
            onClick={() => setParseType("images")}
          >
            <CalendarOutlined style={{ fontSize: "36px", color: "#ad1457", marginBottom: "10px" }} />
            <div style={{ fontWeight: "bold", color: "#880e4f" }}>+API</div>
          </Card>
        </Col>
        <Col>
          <Card
            hoverable
            style={cardStyle("#bbdefb")} // Светло-розовый фон
            onClick={() => setParseType("images_download")}
          >
            <PictureOutlined style={{ fontSize: "36px", color: "#0d47a1", marginBottom: "10px" }} />
            <div style={{ fontWeight: "bold", color: "#1565c0" }}>+img</div>
          </Card>
        </Col>
        <Col>
        <Card
          hoverable
          style={cardStyle("#d1c4e9")} // Светло-фиолетовый фон
          onClick={() => setParseType("news")}
        >
          <FileTextOutlined style={{ fontSize: "36px", color: "#512da8", marginBottom: "10px" }} />
          <div style={{ fontWeight: "bold", color: "#4a148c" }}>+news</div>
        </Card>
      </Col>
      </Row>
    </div>
  );
};

export default ParserSelect;
