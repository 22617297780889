// components/StageProgress.jsx
import React from "react";
import { Progress, Tooltip } from "antd";
import {
  DownloadOutlined,
  ImportOutlined,
  EnvironmentOutlined,
  SearchOutlined,
  CheckCircleOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";

// Стадии (примерные)
const stages = [
  { label: "Сбор данных", icon: <DownloadOutlined /> },
  { label: "Импорт", icon: <ImportOutlined /> },
  { label: "Нормализация адреса", icon: <EnvironmentOutlined /> },
  { label: "Поиск дубликатов", icon: <SearchOutlined /> },
  { label: "Успех", icon: <CheckCircleOutlined /> },
];

// Компонент, который показывает прогресс (stageIndex) среди stages[]
const StageProgress = ({ currentStage }) => {
  const stageIndex = stages.findIndex((s) => s.label === currentStage);
  if (stageIndex < 0) {
    // Нет данных
    return (
      <Progress
        percent={0}
        steps={stages.length}
        format={() => (
          <Tooltip title="Нет данных">
            <QuestionCircleOutlined style={{ color: "gray" }} />
          </Tooltip>
        )}
      />
    );
  }

  const percent = ((stageIndex + 1) / stages.length) * 100;
  return (
    <Progress
      percent={percent}
      steps={stages.length}
      format={() => (
        <Tooltip title={stages[stageIndex].label}>
          {stages[stageIndex].icon}
        </Tooltip>
      )}
    />
  );
};

export default StageProgress;
