import React from 'react';
import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  ToggleButton,
  ToggleButtonGroup,
  Box,
} from '@mui/material';
import { AiOutlineCheckCircle, AiOutlineCloseCircle, AiOutlineCalendar } from 'react-icons/ai';
import { FaUserCog } from 'react-icons/fa';

const CRMFilterForm = ({
  activityStatuses,
  setActivityStatuses,
  workStatuses,
  setWorkStatuses,
  selectedClientStatus,
  setSelectedClientStatus,
  selectedDaysWithoutActivity,
  setSelectedDaysWithoutActivity,
  contactDueOptions,
  setContactDueOptions,
  showHidden,
  setShowHidden,
}) => {
  // Переключатели
  const handleToggleActivity = (event, newActivity) => {
    // Если newActivity возвращается как null (пользователь снял выбор), устанавливаем значение "ВСЕ"
    setActivityStatuses(newActivity !== null ? newActivity : "ВСЕ");
  };
  const handleToggleWork = (event, newWork) => {
    setWorkStatuses(newWork);
  };
  const handleToggleContactDue = (event, newValues) => {
    setContactDueOptions(newValues);
  };
  const handleToggleShowHidden = () => {
    setShowHidden(!showHidden);
  };

  // Опции
  const clientStatusOptions = [
    { value: 'Все', label: 'Все' },
    { value: 'no_status', label: 'Нет статуса' },
    { value: 'primary_contact', label: 'Первичный контакт' },
    { value: 'qualified', label: 'Квалифицирован' },
    { value: 'qualified_plus', label: 'Квалифицирован+' },
    { value: 'lead', label: 'Лид' },
    { value: 'appointment_set', label: 'Назначена встреча' },
    { value: 'appointment_confirmed', label: 'Встреча подтверждена' },
    { value: 'appointment_held', label: 'Встреча состоялась' },
    { value: 'contract_signed', label: 'Договор подписан' },
  ];

  const daysOptions = [
    { value: '', label: 'Все' },
    ...Array.from({ length: 15 }, (_, index) => ({
      value: (index + 1).toString(),
      label: `${index + 1} дней`,
    })),
  ];

  return (
    <Box sx={{ mb: 1, mt: 1, width: '100%' }}>
      {/* Первый ряд: 4 элемента */}
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
          gap: 3,
          mb: 2
        }}
      >
        <Box>
          {/* Активности */}
          <ToggleButtonGroup
            value={activityStatuses}
            onChange={handleToggleActivity}
            color="primary"
            size="small"
          >
            <ToggleButton
              value="С активностями"
              aria-label="with-activity"
              sx={{ whiteSpace: 'nowrap' }}
            >
              <AiOutlineCheckCircle style={{ marginRight: 4 }} />
              С активностями
            </ToggleButton>
            <ToggleButton
              value="Без активностей"
              aria-label="no-activity"
              sx={{ whiteSpace: 'nowrap' }}
            >
              <AiOutlineCloseCircle style={{ marginRight: 4 }} />
              Без активностей
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>

        <Box>
          {/* Статус работы */}
          <ToggleButtonGroup
            value={workStatuses}
            onChange={handleToggleWork}
            color="secondary"
            size="small"
          >
            <ToggleButton
              value="В работе"
              aria-label="in-work"
              sx={{ whiteSpace: 'nowrap' }}
            >
              <FaUserCog style={{ marginRight: 4 }} />
              В работе
            </ToggleButton>
            <ToggleButton
              value="Не в работе"
              aria-label="not-work"
              sx={{ whiteSpace: 'nowrap' }}
            >
              <AiOutlineCloseCircle style={{ marginRight: 4 }} />
              Не в работе
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>

        <Box>
          {/* Дата контакта */}
          {/* <ToggleButtonGroup
            value={contactDueOptions}
            exclusive
            onChange={handleToggleContactDue}
            color="primary"
            size="small"
          >
            <ToggleButton
              value="today"
              aria-label="today"
              sx={{ whiteSpace: 'nowrap' }}
            >
              <AiOutlineCalendar style={{ marginRight: 4 }} />
              Контакт: Сегодня
            </ToggleButton>
            <ToggleButton
              value="tomorrow"
              aria-label="tomorrow"
              sx={{ whiteSpace: 'nowrap' }}
            >
              <AiOutlineCalendar style={{ marginRight: 4 }} />
              Завтра
            </ToggleButton>
          </ToggleButtonGroup> */}
          <ToggleButtonGroup
            value={contactDueOptions}     // '' | 'today' | 'tomorrow'
            exclusive
            onChange={(event, newValue) => {
              // Если пользователь кликнул повторно по активной кнопке, будет null
              if (newValue !== null) {
                setContactDueOptions(newValue);   // 'today' или 'tomorrow'
              } else {
                // Сбрасываем фильтр (как раньше '' означало "Все")
                setContactDueOptions('');
              }
            }}
            color="primary"
            size="small"
          >
            <ToggleButton value="today" aria-label="today" sx={{ whiteSpace: 'nowrap' }}>
              <AiOutlineCalendar style={{ marginRight: 4 }} />
              Контакт: Сегодня
            </ToggleButton>
            <ToggleButton value="tomorrow" aria-label="tomorrow" sx={{ whiteSpace: 'nowrap' }}>
              <AiOutlineCalendar style={{ marginRight: 4 }} />
              Завтра
            </ToggleButton>
          </ToggleButtonGroup>

        </Box>

        <Box sx={{ minWidth: 150 }}>
          {/* Статус клиента */}
          <FormControl fullWidth size="small">
            <InputLabel id="client-status-select-label">Статус клиента</InputLabel>
            <Select
              labelId="client-status-select-label"
              value={selectedClientStatus}
              onChange={(e) => setSelectedClientStatus(e.target.value)}
              label="Статус клиента"
            >
              {clientStatusOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Box>

      {/* Второй ряд: 2 элемента */}
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
          gap: 3
        }}
      >
        <Box sx={{ flex: 1, maxWidth: 300 }}>
          {/* Дней без активностей */}
          <FormControl fullWidth size="small">
            <InputLabel id="days-without-activity-select-label">
              Дней без активностей
            </InputLabel>
            <Select
              labelId="days-without-activity-select-label"
              value={selectedDaysWithoutActivity}
              onChange={(e) => setSelectedDaysWithoutActivity(e.target.value)}
              label="Дней без активностей"
            >
              {daysOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>

        <Box>
          {/* Показать скрытые */}
          <ToggleButton
            value={showHidden}
            selected={showHidden}
            onClick={handleToggleShowHidden}
            color="primary"
            size="small"
            sx={{ whiteSpace: 'nowrap' }}
          >
            {showHidden ? 'Скрыть скрытые' : 'Показать скрытые'}
          </ToggleButton>
        </Box>
      </Box>
    </Box>
  );
};

export default CRMFilterForm;
