import React from 'react';

export default function VideoBlock() {
  return (
    <div className="w-full bg-white py-10">
      <div className="container mx-auto px-4 text-center">
        <h2 className="text-2xl md:text-4xl font-bold text-blue-900 mb-6">
          Короткий обзор продукта
        </h2>
        <p className="text-gray-700 mb-8">
          Посмотрите короткое видео о том, как работает наша платформа и 
          насколько она упрощает вашу работу с недвижимостью.
        </p>
        {/* Встроенное видео (YouTube пример) */}
        <div className="relative pb-56 mb-6" style={{ height: 0 }}>
          <iframe
            className="absolute top-0 left-0 w-full h-full"
            src="https://www.youtube.com/embed/Wch3gJG2GJ4" 
            title="Product Overview"
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </div>
      </div>
    </div>
  );
}
