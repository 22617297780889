import React, { useEffect, useState } from 'react';

const SubjectSort = ({ onSort, isCRM }) => {
  const [sortOrder, setSortOrder] = useState(() => {
    // Считываем значение сортировки из localStorage при первом рендере
    return localStorage.getItem('sortOrder') || '';
  });

  // Сохраняем параметр сортировки в localStorage при изменении
  useEffect(() => {
    localStorage.setItem('sortOrder', sortOrder);
  }, [sortOrder]);

  const handleSortChange = (event) => {
    event.preventDefault(); // Предотвращаем перезагрузку страницы
    const value = event.target.value;
    setSortOrder(value);
    onSort(value);
  };

  return (
    <div className="mb-6">
      <label htmlFor="sortOrder" className="block text-gray-700 font-bold mb-2">
        Сортировать по:
      </label>
      <select
        id="sortOrder"
        value={sortOrder}
        onChange={handleSortChange}
        className="form-select mt-1 block w-full p-2 border rounded-md"
      >
        <option value="">Не сортировать</option>
        <option value="asc">По дате создания (по возрастанию)</option>
        <option value="desc">По дате создания (по убыванию)</option>
        {isCRM && (
          <>
            <option value="last_activity_asc">По дате последней активности (по возрастанию)</option>
            <option value="last_activity_desc">По дате последней активности (по убыванию)</option>
            <option value="contact_due_asc">По дате последнего контакта (по возрастанию)</option>
            <option value="contact_due_desc">По дате последнего контакта (по убыванию)</option>
          </>
        )}
      </select>
    </div>
  );
};

export default SubjectSort;
