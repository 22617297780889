import React from 'react';
import { HiLocationMarker, HiHome } from 'react-icons/hi';

const CardLeftDetailsNoCrm = ({ subject }) => {
  return (
    <div className="flex-1">
      <h2 className="text-lg font-bold mb-3">Характеристики объекта</h2>
      <div className="flex flex-col gap-3 text-sm text-gray-700">

        {/* Если понадобилось вернуть "Город": */}
        {/* 
        <div className="flex items-center space-x-2">
          <HiLocationMarker className="text-blue-500" size={18} />
          <span className="font-semibold">Город:</span>
          <span>{subject.city || '-'}</span>
        </div>
        */}

        <div className="flex items-center space-x-2">
          <HiLocationMarker className="text-green-500" size={18} />
          <span className="font-semibold">Район:</span>
          <span>{subject.district || '-'}</span>
        </div>

        <div className="flex items-center space-x-2">
          <HiLocationMarker className="text-pink-500" size={18} />
          <span className="font-semibold">Адрес:</span>
          <span>{subject.adress || '-'}</span>
        </div>

        <div className="flex items-center space-x-2">
          <HiHome className="text-indigo-500" size={18} />
          <span className="font-semibold">Активно:</span>
          <span>{subject.activ ? 'Да' : 'Нет'}</span>
        </div>

        <div className="flex items-center space-x-2">
          <HiHome className="text-orange-500" size={18} />
          <span className="font-semibold">Тип:</span>
          <span>{subject.type === 'secondary' ? 'Вторичка' : subject.type || '-'}</span>
        </div>

        <div className="flex items-center space-x-2">
          <HiHome className="text-purple-500" size={18} />
          <span className="font-semibold">Тип продавца:</span>
          <span>{subject.seller_type || '-'}</span>
        </div>

        {/* При желании рендерим другие поля, которые приходят из extendedData: */}
        {/* 
        <div className="flex items-center space-x-2">
          <HiHome className="text-red-500" size={18} />
          <span className="font-semibold">Этаж:</span>
          <span>{subject.floor ? `${subject.floor}/${subject.floors}` : '-'}</span>
        </div>
        <div className="flex items-center space-x-2">
          <HiHome className="text-teal-500" size={18} />
          <span className="font-semibold">Площадь:</span>
          <span>{subject.square || '-'}</span>
        </div>
        */}
      </div>
    </div>
  );
};

export default CardLeftDetailsNoCrm;
