// src/components/ProcessManager/tables/DownloadedImagesTable.jsx
import React from "react";
import { Table, Tag, Button } from "antd";
import { StopOutlined, PlusOutlined } from "@ant-design/icons";
import { useProcesses } from "../hooks/useProcesses";
import { statusLabels, statusColors } from "../components/statusLabels";

const DownloadedImagesTable = ({ user }) => {
  // Получаем процессы через общий хук
  const { processes } = useProcesses(user);

  // Фильтруем процессы по типу "downloadedImages"
  const filteredProcesses = processes.filter(
    (process) => process.type === "Загрузка картинок"
  );

  const columns = [
    {
      title: "Дата и время начала",
      dataIndex: "start_time",
      key: "start_time",
      align: "center",
      render: (text) => {
        if (!text) return "Нет данных";
        const dateObj = new Date(text);
        const dateStr = dateObj.toLocaleDateString();
        const timeStr = dateObj.toLocaleTimeString();
        return (
          <div style={{ fontSize: "12px", lineHeight: "1.2" }}>
            <div>{dateStr}</div>
            <div>{timeStr}</div>
          </div>
        );
      },
    },
    {
      title: "Город",
      dataIndex: "city",
      key: "city",
      align: "center",
    },
    {
      title: "Статус",
      dataIndex: "status",
      key: "status",
      align: "center",
      render: (statusValue) => (
        <Tag color={statusColors[statusValue] || "default"}>
          {statusLabels[statusValue] || "Нет данных"}
        </Tag>
      ),
    },
    {
      title: "Статус выполнения",
      dataIndex: "current_stage",
      key: "current_stage",
      align: "center",
      render: (status) => (
        <Tag>
          {status || "Нет данных"}
        </Tag>
      ),
    },
    {
      title: "Остановка процесса",
      key: "stop_process",
      align: "center",
      render: () => (
        // Кнопка с элегантной иконкой для остановки процесса
        <Button type="primary" danger icon={<StopOutlined />} />
      ),
    },
    {
      title: "Логи",
      key: "logs",
      align: "center",
      render: () => (
        // Заготовка для значка плюса, который будет открывать/скрывать логи.
        <Button type="text" icon={<PlusOutlined />} />
      ),
    },
    {
      title: "Количество обработанных объектов",
      dataIndex: "updated_objects_count",
      key: "updated_objects_count",
      align: "center",
      render: (count) => (count !== undefined ? count : "0"),
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={filteredProcesses}
      rowKey="id"
      pagination={{ pageSize: 10 }}
      style={{ width: "100%" }}
    />
  );
};

export default DownloadedImagesTable;
