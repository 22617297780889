// src/components/StartImgDownloads.js
import React, { useState, useEffect } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { message, Button, Select, Input, Card, Form } from "antd";

const { Option } = Select;

const StartImgDownloads = ({ user, onBack }) => {
  const [cities, setCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState("");
  const [count, setCount] = useState("all");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/dashboard/api/cities/`, {
        withCredentials: true,
      })
      .then((res) => {
        if (res.data && res.data.results) {
          setCities(res.data.results);
        }
      })
      .catch((error) => {
        console.error("Ошибка при получении списка городов:", error);
        message.error("Не удалось загрузить города.");
      });
  }, []);

  const handleStartEnrichment = () => {
    if (!user) {
      message.warning("Сначала войдите в систему (пользователь не аутентифицирован).");
      return;
    }
    if (!selectedCity) {
      message.warning("Выберите город из списка.");
      return;
    }

    setLoading(true);

    axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/start_img_downloads/`,
        {
          city_name: selectedCity,
          count: count,
        },
        {
          headers: {
            "X-CSRFToken": Cookies.get("csrftoken") || "",
          },
          withCredentials: true,
        }
      )
      .then((response) => {
        message.success(response.data.message || "Процесс загрузки запущен!");
      })
      .catch((error) => {
        if (error.response) {
          const { status, data } = error.response;
          console.error("Ошибка при запуске загрузки:", status, data);
          if (data && data.error) {
            message.error(`Ошибка: ${data.error}`);
          } else {
            message.error("Ошибка при запуске загрузки. Код " + status);
          }
        } else {
          message.error("Ошибка при запуске загрузки (нет ответа).");
          console.error(error);
        }
      })
      .finally(() => setLoading(false));
  };

  return (
    <div style={{ display: "flex", justifyContent: "center", margin: "20px" }}>
      <Card title="Загрузка картинок" style={{ width: 500 }}>
        <Form layout="vertical">
          <Form.Item label="Выберите город">
            <Select
              placeholder="Выберите город"
              value={selectedCity}
              onChange={setSelectedCity}
            >
              {cities.map((city) => (
                <Option key={city.id} value={city.name}>
                  {city.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="Количество объектов">
            <Input
              placeholder="Количество объектов (число или 'all')"
              value={count}
              onChange={(e) => setCount(e.target.value)}
            />
          </Form.Item>
          <Form.Item>
            <Button type="primary" onClick={handleStartEnrichment} loading={loading} block>
              Загрузить на сервер
            </Button>
          </Form.Item>
          {onBack && (
            <Form.Item>
              <Button onClick={onBack} block>
                Назад
              </Button>
            </Form.Item>
          )}
        </Form>
      </Card>
    </div>
  );
};

export default StartImgDownloads;
