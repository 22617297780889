// ProcessManager.jsx
import React, { useState } from "react";
import { Button } from "antd";
import ParsersTable from "./tables/ParsersTable";
import DownloadedImagesTable from "./tables/ImagesTable";
// import BuildingYearTable from "./tables/BuildingYearTable";
import APITable from "./tables/ApiTable";

const ProcessManager = ({ user, processesForImgAPI }) => {
  const [activeType, setActiveType] = useState("parsers"); 
  
  // "parsers" | "images" | "buildingYear"

  return (
    <div style={{ margin: 20 }}>
      <div style={{ marginBottom: 20 }}>
        <Button
          onClick={() => setActiveType("parsers")}
          type={activeType === "parsers" ? "primary" : "default"}
          style={{ marginRight: 10 }}
        >
          Общие парсеры
        </Button>
        <Button
          onClick={() => setActiveType("imgAPI")}
          type={activeType === "imgAPI" ? "primary" : "default"}
          style={{ marginRight: 10 }}
        >
          +API
        </Button>
        <Button
          onClick={() => setActiveType("images")}
          type={activeType === "images" ? "primary" : "default"}
          style={{ marginRight: 10 }}
        >
          +img
        </Button>
        {/* <Button
          onClick={() => setActiveType("buildingYear")}
          type={activeType === "buildingYear" ? "primary" : "default"}
        >
          Год постройки
        </Button> */}
      </div>

      {activeType === "parsers" && <ParsersTable user={user} />}
      {activeType === "images" && <DownloadedImagesTable user={user} />}
      {/* {activeType === "buildingYear" && <BuildingYearTable user={user} />} */}
      {activeType === "imgAPI" && (
        <APITable user={user} />
      )}
      
    </div>
  );
};

export default ProcessManager;
