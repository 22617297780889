import React from 'react';
import solutionImg1 from '../assets/solution1.png';
import solutionImg2 from '../assets/solution2.png';
import solutionImg3 from '../assets/solution3.png';

function SolutionSectionItem({ title, text, imgOnLeft, imgSrc }) {
    return (
      <div className="flex flex-col md:flex-row items-center mb-24">
        {imgOnLeft && (
          <div className="md:w-1/2 w-full mb-6 md:mb-0 flex justify-center p-4">
            <img src={imgSrc} alt={title} className="max-w-lg" />
          </div>
        )}
        <div className="md:w-1/2 w-full px-6">
          <h3 className="text-2xl font-bold text-blue-900 mb-4">{title}</h3>
          <p className="text-gray-700 text-lg">{text}</p>
        </div>
        {!imgOnLeft && (
          <div className="md:w-1/2 w-full mt-6 md:mt-0 flex justify-center p-4">
            <img src={imgSrc} alt={title} className="max-w-lg" />
          </div>
        )}
      </div>
    );
  }
  

export default function SolutionSections() {
  return (
    <div className="w-full bg-blue-50 py-10">
      <div className="container mx-auto px-4">
        <h2 className="text-2xl md:text-4xl font-bold text-blue-900 mb-8">
          Что мы предлагаем?
        </h2>
        
        <SolutionSectionItem
          title="Удобный поиск на карте"
          text="Просматривайте как активные объявления, так и архивные снятые объявления в нужном районе. 
                Мгновенный доступ к истории каждого объекта."
          imgOnLeft={true}
          imgSrc={solutionImg1}
        />
        
        <SolutionSectionItem
          title="Поиск по ссылке"
          text="Просто вставьте ссылку с любого портала объявлений — наша система найдёт все дубликаты, покажет 
                историю размещений, цен и фотографий."
          imgOnLeft={false}
          imgSrc={solutionImg2}
        />

        <SolutionSectionItem
          title="Вся история объектов"
          text="Узнайте, какие услуги продвижения применялись, кто размещал объявление, какие были изменения цен 
                или снятие с публикации. Всё в одном удобном интерфейсе."
          imgOnLeft={true}
          imgSrc={solutionImg3}
        />
      </div>
    </div>
  );
}
