import React from 'react';
import { HiCheckCircle, HiOutlineXCircle } from 'react-icons/hi';

const CardRightFeaturesNoCrm = ({ features }) => {
  // Утилита для определения истинности значения
  const isTruthy = (val) => {
    if (typeof val === 'boolean') return val;
    if (typeof val === 'number') return val > 0;
    if (typeof val === 'string') return val.trim() !== '';
    return false;
  };

  return (
    <div className="flex-1 mt-6 md:mt-0">
    <h2 className="text-lg font-bold mb-3">Доступная информация в базе:</h2>

      <div className="grid grid-cols-1 sm:grid-cols-2 gap-3">
        {features.map((feat, index) => {
          const truthy = isTruthy(feat.value);
          return (
            <div
              key={`${feat.label}-${index}`}
              className="flex items-center space-x-2 p-2 rounded-md bg-white shadow-sm hover:shadow-md transition-shadow"
            >
              <div>{feat.icon}</div>
              {truthy ? (
                <HiCheckCircle className="text-green-500" size={18} />
              ) : (
                <HiOutlineXCircle className="text-gray-300" size={18} />
              )}
              <span className="text-sm text-gray-700 font-medium">
                {feat.label}
                {typeof feat.value === 'number' ? `: ${feat.value}` : ''}
              </span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default CardRightFeaturesNoCrm;
