import React from 'react';
import { Box, Typography } from '@mui/material';

const CardHeaderNoCrm = ({ subject }) => {
  const backgroundColor = subject.activ ? '#1565c0' : '#cbd5e1';

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor,
        padding: '8px',
        borderRadius: '4px',
      }}
    >
      {/* Левая колонка: название объекта */}
      <Box>
        <Typography variant="h6" sx={{ color: '#fff', fontWeight: 'bold' }}>
          {subject.name || `Объект #${subject.id}`}
        </Typography>
      </Box>

      {/* Центральная колонка: дата создания */}
      <Box>
        <Typography variant="h7" sx={{ color: '#fff' }}>
          Добавлено в базу: {subject.creat_data || 'Нет даты'}
        </Typography>
      </Box>
    </Box>
  );
};

export default CardHeaderNoCrm;
