import React from 'react';

export default function ProblemSection() {
  return (
    <div className="w-full bg-white py-10">
      <div className="container mx-auto px-4">
        <h2 className="text-2xl md:text-4xl font-bold text-blue-900 mb-6">
          С какими проблемами вы сталкиваетесь?
        </h2>
        <p className="text-gray-700 mb-6 leading-relaxed">
          Бывали ли у вас ситуации, когда:
        </p>
        <ul className="list-disc list-inside space-y-2 text-gray-700">
          <li>
            Объявление было снято, и больше нет возможности его посмотреть, а значит, 
            может быть упущен контакт собственника.
          </li>
          <li>
            При оценке квартиры нет информации о том, что уже продавалось в районе 
            и по каким ценам.
          </li>
          <li>
            Видите объявление, но не знаете его достоверной истории (сколько было агентов, 
            как менялась цена).
          </li>
          <li>
            Клиент говорит, что в его доме продавалась квартира за «очень высокую» цену – 
            без инструментов проверки тяжело понять, блеф это или нет.
          </li>
          <li>
            Из-за перечисленных проблем теряется уверенность на переговорах, и сложнее 
            расположить клиента к себе.
          </li>
        </ul>
      </div>
    </div>
  );
}
