import React, { useState, useEffect } from 'react';
import axios from 'axios';
import clsx from 'clsx';
import { FiCheck, FiTrash2 } from 'react-icons/fi';
import { useInView } from 'react-intersection-observer';

const allIntervals = [
  { value: '1_hour', label: '1 час', color: 'bg-red-200' },
  { value: '3_hours', label: '3 часа', color: 'bg-yellow-200' },
  { value: '6_hours', label: '6 часов', color: 'bg-green-200' },
  { value: '1_day', label: '1 день', color: 'bg-red-100' },
  { value: '2_days', label: '2 дня', color: 'bg-orange-100' },
  { value: '5_days', label: '5 дней', color: 'bg-blue-100' },
  { value: '7_days', label: '7 дней', color: 'bg-purple-100' },
  { value: '10_days', label: '10 дней', color: 'bg-pink-100' },
  { value: '14_days', label: '14 дней', color: 'bg-green-50' },
  { value: '20_days', label: '20 дней', color: 'bg-orange-50' },
  { value: '25_days', label: '25 дней', color: 'bg-yellow-50' },
  { value: '30_days', label: '30 дней', color: 'bg-green-50' },
];

const ContactInterval = ({ subjectId }) => {
  const [selectedInterval, setSelectedInterval] = useState('');
  const [dateSet, setDateSet] = useState('');
  const [contactDueDate, setContactDueDate] = useState('');
  const [contactIntervalId, setContactIntervalId] = useState(null);
  const [progress, setProgress] = useState(100);
  const [progressColor, setProgressColor] = useState('bg-gray-300');
  const [isLoading, setIsLoading] = useState(false);
  const [hasLoaded, setHasLoaded] = useState(false);
  
  // Используем хук useInView
  const { ref, inView } = useInView({
    threshold: 0.1,
    triggerOnce: true, // Загрузка только один раз
  });

  // Логирование данных при изменении стейта
  // useEffect(() => {
    // console.log('Текущий интервал:', selectedInterval);
    // console.log('Дата установления:', dateSet);
    // console.log('Дата следующего контакта:', contactDueDate);
  // }, [selectedInterval, dateSet, contactDueDate]);

  // Функция для расчета прогресса и определения цвета прогресс-бара
  const calculateProgress = (dateSet, dueDate) => {
    const now = new Date();
    const totalTime = dueDate - dateSet;
    const timeLeft = dueDate - now;

    const percentage = (timeLeft / totalTime) * 100;

    let color = 'bg-gray-300';

    if (timeLeft <= 0) {
      // Если дата контакта уже прошла, делаем фиолетовую полосу
      color = 'bg-purple-500';
      return {
        percentage: 1, // Устанавливаем минимальный процент, чтобы полоса была видна
        color,
      };
    } else if (percentage > 50) {
      color = 'bg-green-500';
    } else if (percentage > 20) {
      color = 'bg-orange-400';
    } else if (percentage > 0) {
      color = 'bg-red-400';
    }

    // console.log('Выбранный цвет:', color);

    return {
      percentage: Math.max(0, percentage),
      color,
    };
  };

  // Функция для получения данных о последнем установленном интервале
  const fetchData = async () => {
    setIsLoading(true);
    try {
      console.log('Загружены данные интервалов');
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/dashboard/api/contact-intervals/?subject_id=${subjectId}`,
        { 
          withCredentials: true,
          headers: {
            'X-CSRFToken': getCsrfToken(),
          },
        }
      );

      if (response.data.results.length > 0) {
        // Найдем интервал с самым большим id
        const latestIntervalData = response.data.results.reduce((latest, current) => {
          return current.id > latest.id ? current : latest;
        });

        // console.log('Самый последний интервал по id:', latestIntervalData);

        setSelectedInterval(latestIntervalData.interval);
        setDateSet(latestIntervalData.date_set);
        setContactDueDate(latestIntervalData.contact_due_date);
        setContactIntervalId(latestIntervalData.id);

        const progressData = calculateProgress(
          new Date(latestIntervalData.date_set),
          new Date(latestIntervalData.contact_due_date)
        );
        setProgress(progressData.percentage);
        setProgressColor(progressData.color);
      } else {
        console.log('Нет данных о интервалах для данного subject.');
      }
    } catch (error) {
      console.error('Ошибка получения данных о периодичности контакта:', error);
    } finally {
      setIsLoading(false);
      setHasLoaded(true);
    }
  };

  // Триггер загрузки данных при попадании в зону видимости
  useEffect(() => {
    if (inView && !hasLoaded) {
      fetchData();
    }
  }, [inView, hasLoaded, subjectId]);

  // Обновление прогресса по времени
  useEffect(() => {
    if (dateSet && contactDueDate) {
      // console.log('Расчет прогресса на основании дат:', dateSet, contactDueDate);
      const interval = setInterval(() => {
        const { percentage, color } = calculateProgress(new Date(dateSet), new Date(contactDueDate));
        setProgress(percentage);
        setProgressColor(color);

        // console.log('Установлен цвет прогресс-бара:', color);
        // console.log('Процент прогресса:', percentage);
      }, 30000); // Каждые 30 секунд

      return () => clearInterval(interval);
    } else {
      setProgress(0);
      setProgressColor('bg-gray-300');
    }
  }, [dateSet, contactDueDate]);

  // Функция для получения CSRF-токена из cookies
  function getCsrfToken() {
    const cookieValue = document.cookie
      .split('; ')
      .find(row => row.startsWith('csrftoken='))
      ?.split('=')[1];
    return cookieValue;
  }

  // Функция для установки или обновления интервала
  const handleSetInterval = async () => {
    try {
      let dueDate = new Date();
      switch (selectedInterval) {
        case '1_hour':
          dueDate.setHours(dueDate.getHours() + 1);
          break;
        case '3_hours':
          dueDate.setHours(dueDate.getHours() + 3);
          break;
        case '6_hours':
          dueDate.setHours(dueDate.getHours() + 6);
          break;
        case '1_day':
          dueDate.setDate(dueDate.getDate() + 1);
          break;
        case '2_days':
          dueDate.setDate(dueDate.getDate() + 2);
          break;
        case '5_days':
          dueDate.setDate(dueDate.getDate() + 5);
          break;
        case '7_days':
          dueDate.setDate(dueDate.getDate() + 7);
          break;
        case '10_days':
          dueDate.setDate(dueDate.getDate() + 10);
          break;
        case '14_days':
          dueDate.setDate(dueDate.getDate() + 14);
          break;
        case '20_days':
          dueDate.setDate(dueDate.getDate() + 20);
          break;
        case '25_days':
          dueDate.setDate(dueDate.getDate() + 25);
          break;
        case '30_days':
          dueDate.setDate(dueDate.getDate() + 30);
          break;
        default:
          console.error('Неподдерживаемый интервал:', selectedInterval);
          return;
      }

      const contactDueDateString = dueDate.toISOString();

      console.log('Создаем новый интервал');
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/dashboard/api/contact-intervals/`, {
        subject: subjectId,
        interval: selectedInterval,
        contact_due_date: contactDueDateString,
      }
      ,
      { 
        withCredentials: true,
        headers: {
          'X-CSRFToken': getCsrfToken(),
        },
      }
    );

      // После создания нового интервала обновляем список интервалов
      setDateSet(new Date().toISOString());
      setContactDueDate(contactDueDateString);
      setContactIntervalId(response.data.id); // Обновляем id последнего интервала

      // Обновляем прогресс сразу после установки интервала
      const progressData = calculateProgress(new Date(), new Date(contactDueDateString));
      setProgress(progressData.percentage);
      setProgressColor(progressData.color);
    } catch (error) {
      console.error('Ошибка создания нового интервала:', error);
    }
  };

  // Функция для сброса интервала
  const handleResetInterval = async () => {
    try {
      if (contactIntervalId) {
        await axios.delete(
          `${process.env.REACT_APP_API_URL}/dashboard/api/contact-intervals/${contactIntervalId}/`,
          { 
            withCredentials: true,
            headers: {
              'X-CSRFToken': getCsrfToken(),
            },
          }
        );
        setSelectedInterval('');
        setDateSet('');
        setContactDueDate('');
        setContactIntervalId(null);
        setProgress(100);
        setProgressColor('bg-gray-300');
        console.log('Интервал успешно сброшен');
      } else {
        console.log('Нет активного интервала для сброса');
      }
    } catch (error) {
      console.error('Ошибка сброса интервала:', error);
    }
  };

  return (
    <div
      ref={ref}
      className="w-full p-4 border rounded-lg shadow-md bg-gradient-to-r from-white to-gray-100"
    >
      {isLoading ? (
        <p className="text-sm text-gray-500 text-center">Загрузка...</p>
      ) : hasLoaded && (
        <>
          <h3 className="text-lg font-bold text-gray-700 mb-3">Периодичность контактов</h3>
          <div className="grid grid-cols-6 gap-2 mb-4">
            {allIntervals.map((interval) => (
              <button
                key={interval.value}
                value={interval.value}
                onClick={() => setSelectedInterval(interval.value)}
                className={clsx(
                  "p-1 rounded-md text-center text-xs font-semibold text-gray-700 transition-all duration-150 transform",
                  interval.color,
                  selectedInterval === interval.value ? "ring-2 ring-blue-300" : "hover:ring-1 hover:ring-gray-300"
                )}
              >
                {interval.label}
              </button>
            ))}
          </div>

          <div className="relative w-full h-4 bg-gray-300 rounded-full mb-4">
            <div
              className={`h-full rounded-full transition-width duration-1000 ${progressColor}`}
              style={{ width: `${progress}%` }}
            ></div>
          </div>

          <div className="flex items-center space-x-2 mt-3">
            <button
              onClick={handleSetInterval}
              className="bg-blue-500 text-white p-2 rounded-full hover:bg-blue-600 transition-colors duration-200"
            >
              <FiCheck size={16} />
            </button>
            <button
              onClick={handleResetInterval}
              className="bg-red-400 text-white p-2 rounded-full hover:bg-red-500 transition-colors duration-200"
            >
              <FiTrash2 size={16} />
            </button>

            <div className="ml-2">
              <p className="text-xs text-gray-500">
                Установлено: {dateSet ? new Date(dateSet).toLocaleString('ru-RU') : 'Не установлено'}
              </p>
              <p className="text-xs text-gray-800 font-semibold">
                Следующий контакт: {contactDueDate ? new Date(contactDueDate).toLocaleString('ru-RU') : 'Не установлено'}
              </p>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ContactInterval;
