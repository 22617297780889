import React, { useState } from 'react';

export default function TryItNow() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    userType: '',
  });

  const handleChange = (e) => {
    setFormData({ 
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Обработка формы (например, отправка на сервер)
    alert('Форма отправлена!');
  };

  return (
    <div className="w-full bg-orange-50 py-10">
      <div className="container mx-auto px-4">
        <h2 className="text-2xl md:text-4xl font-bold text-orange-600 mb-4">
          Попробовать бесплатно
        </h2>
        <p className="text-gray-700 mb-8">
          Оставьте заявку на бета-доступ. Всего 20 рублей за одну запись!
        </p>

        <form onSubmit={handleSubmit} className="max-w-md mx-auto space-y-4">
          <div>
            <label className="block text-gray-600 mb-2">Имя</label>
            <input
              type="text"
              name="name"
              className="w-full px-3 py-2 border rounded"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </div>
          <div>
            <label className="block text-gray-600 mb-2">E-mail</label>
            <input
              type="email"
              name="email"
              className="w-full px-3 py-2 border rounded"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>
          <div>
            <label className="block text-gray-600 mb-2">Телефон</label>
            <input
              type="tel"
              name="phone"
              className="w-full px-3 py-2 border rounded"
              value={formData.phone}
              onChange={handleChange}
              required
            />
          </div>
          <div>
            <label className="block text-gray-600 mb-2">Тип пользователя</label>
            <select
              name="userType"
              className="w-full px-3 py-2 border rounded"
              value={formData.userType}
              onChange={handleChange}
              required
            >
              <option value="">Выберите тип</option>
              <option value="agent_private">Частный агент</option>
              <option value="agent_company">Агент из агентства недвижимости</option>
              <option value="broker">Брокер</option>
              <option value="individual">Частное лицо</option>
            </select>
          </div>
          <button
            type="submit"
            className="bg-orange-500 hover:bg-orange-600 text-white px-4 py-2 rounded"
          >
            Отправить
          </button>
        </form>
      </div>
    </div>
  );
}
