// src/components/PublicationsPage/PublicationsPage.jsx
import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { Row, Col, message } from 'antd';

import PublicationsFilter from './PublicationsFilter';
import PublicationsList from './PublicationsList';

function getCsrfToken() {
  const cookieValue = document.cookie
    .split('; ')
    .find((row) => row.startsWith('csrftoken='))
    ?.split('=')[1];
  return cookieValue;
}

function PublicationsPage({ user }) {
  // Источники (справочник)
  const [sources, setSources] = useState([]);

  // Состояние фильтров
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [selectedSources, setSelectedSources] = useState([]);

  // Список публикаций
  const [publications, setPublications] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  // 1) Загружаем список источников
  useEffect(() => {
    if (!user) return; // Если не залогинены, не делаем запрос
    const fetchSources = async () => {
      try {
        setLoading(true);
        const resp = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/sources/`,
          {
            withCredentials: true,
            headers: {
              'X-CSRFToken': getCsrfToken(),
              'Content-Type': 'application/json',
            },
          }
        );
        // Если нет пагинации, resp.data - это массив
        // Если вдруг включена пагинация, берем resp.data.results
        const data = resp.data.results || resp.data;
        setSources(Array.isArray(data) ? data : []);
      } catch (err) {
        console.error('Ошибка при загрузке источников:', err);
        setError('Не удалось загрузить список источников.');
      } finally {
        setLoading(false);
      }
    };
    fetchSources();
  }, [user]);

  // 2) Функция для загрузки ПУБЛИКАЦИЙ (учитывая текущие фильтры)
  const fetchPublications = useCallback(async () => {
    if (!user) return;
    setLoading(true);
    setError('');

    const params = {};
    if (startDate) params.start_date = startDate;
    if (endDate) params.end_date = endDate;
    // Если выбрали источники (длина > 0), передаём. Иначе ничего (все источники).
    // if (selectedSources.length > 0) {
    //   params.sources = selectedSources.join(',');
    // }
    params.sources = selectedSources.join(',');

    try {
      const resp = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/publications/`,
        {
          withCredentials: true,
          headers: {
            'X-CSRFToken': getCsrfToken(),
            'Content-Type': 'application/json',
          },
          params,
        }
      );
      // Так как отключили пагинацию, респ — это просто массив
      // Или если осталась пагинация глобально, берем resp.data.results
      const data = resp.data.results || resp.data;
      setPublications(Array.isArray(data) ? data : []);
    } catch (err) {
      console.error('Ошибка при загрузке публикаций:', err);
      setError('Не удалось загрузить публикации.');
      message.error('Ошибка при загрузке публикаций');
      setPublications([]);
    } finally {
      setLoading(false);
    }
  }, [user, startDate, endDate, selectedSources]);

  // 3) При нажатии на "Применить" в фильтре:
  const handleFilterApply = (filterValues) => {
    setStartDate(filterValues.startDate);
    setEndDate(filterValues.endDate);
    setSelectedSources(filterValues.sources);
  };

  // 4) Когда меняются фильтры (startDate, endDate, selectedSources) => подгружаем новые данные
  useEffect(() => {
    if (!user) return;
    fetchPublications();
  }, [user, startDate, endDate, selectedSources, fetchPublications]);

  return (
    <div style={{ padding: '16px' }}>
      <Row gutter={16}>
        {/* Левая колонка: фильтры */}
        <Col xs={24} sm={24} md={8} lg={6}>
          {/* <h2>Фильтры</h2> */}
          <PublicationsFilter
            sources={sources}
            defaultStartDate={startDate}
            defaultEndDate={endDate}
            defaultSelectedSources={selectedSources}
            loading={loading}
            onFilterApply={handleFilterApply}
          />
        </Col>

        {/* Правая колонка: список */}
        <Col xs={24} sm={24} md={16} lg={18}>
          {/* <h2>Список публикаций</h2> */}
          {error && <p style={{ color: 'red' }}>{error}</p>}

          <PublicationsList
            publications={publications}
            loading={loading}
          />
        </Col>
      </Row>
    </div>
  );
}

export default PublicationsPage;
