// src/App.js
import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import MenuBar from './components/MenuBar';
import AddressNormalizer from './components/AddressNormalizer';
import SubjectListLite from './components/LITE/SubjectListLite';
import RouteDataCollection from './components/routeDataCollection';
import PublicationsPage from './components/PublicationsPage/PublicationsPage';
import HomePage from './components/PUBLIC/HomePage';
import { AuthProvider, useAuth } from './components/AUTH/AuthProvider';

function ProtectedRoute({ roles, children }) {
  const { user } = useAuth();
  
  // Если пользователь не авторизован, возвращаем сообщение об ошибке
  if (!user) return <p>401: Не авторизованы</p>;
  
  // Если указаны роли, но роль пользователя не соответствует – ошибка доступа
  if (roles && !roles.includes(user.role)) {
    return <p>403: Недостаточно прав</p>;
  }
  
  return children;
}

function AppRoutes() {
  const { user, loading, errorMessage, handleLogin, handleLogout } = useAuth();
  
  return (
    <>
      <MenuBar
        user={user}
        loading={loading}
        errorMessage={errorMessage}
        onLogin={handleLogin}
        onLogout={handleLogout}
      />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/lite_subjects" element={<SubjectListLite user={user} />} />
        <Route
          path="/normalize-addresses"
          element={
            <ProtectedRoute roles={['admin', 'manager']}>
              <AddressNormalizer user={user} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/routeDataCollection"
          element={
            <ProtectedRoute roles={['admin', 'manager']}>
              <RouteDataCollection user={user} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/publications"
          element={
            <ProtectedRoute roles={['admin', 'manager']}>
              <PublicationsPage user={user} />
            </ProtectedRoute>
          }
        />
      </Routes>
    </>
  );
}

function App() {
  return (
    <AuthProvider>
      <BrowserRouter>
        <AppRoutes />
      </BrowserRouter>
    </AuthProvider>
  );
}

export default App;
