// src/components/ProcessManager/hooks/useProcesses.js
import { useState, useEffect } from "react";
import axios from "axios";
import Cookies from "js-cookie";
// Импортируем словарь статусов парсинга для отображения читаемых названий
import { parsingStatusLabels } from "../components/parsingStatusLabels";

const getCsrfToken = () => {
  const csrfToken = Cookies.get("csrftoken");
  return csrfToken || "";
};

export function useProcesses(user) {
  const [processes, setProcesses] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchProcesses = async () => {
    if (!user) {
      setProcesses([]);
      return;
    }
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/process-runs/`,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": getCsrfToken(),
          },
        }
      );
      const { results } = response.data;

      // Для каждого процесса, если есть поле parsing_process, дополнительно получаем данные о парсинге
      const updatedResults = await Promise.all(
        results.map(async (processPars) => {
          // 1) Если у процесса есть parsing_process – делаем запрос для получения его статуса
          if (processPars.parsing_process) {
            try {
              const parsingResponse = await axios.get(
                `${process.env.REACT_APP_API_URL}/api/parsing-processes/${processPars.parsing_process}/`,
                {
                  withCredentials: true,
                  headers: {
                    "Content-Type": "application/json",
                    "X-CSRFToken": getCsrfToken(),
                  },
                }
              );
              processPars.parsing_status =
                parsingStatusLabels[parsingResponse.data.status] ||
                parsingResponse.data.status;
              processPars.parsing_status_raw = parsingResponse.data.status;
              processPars.parsing_successful = parsingResponse.data.successful;
              processPars.object_count =
                parsingResponse.data.object_count || "—";
              processPars.stop_requested =
                parsingResponse.data.stop_requested;
            } catch (error) {
              console.error("Ошибка загрузки статуса парсинга:", error);
              processPars.parsing_status = null;
              processPars.parsing_status_raw = null;
              processPars.parsing_successful = null;
              processPars.object_count = "—";
              processPars.stop_requested = false;
            }
          } else {
            // Если parsing_process отсутствует, заполняем поля значениями по умолчанию
            processPars.parsing_status = null;
            processPars.parsing_status_raw = null;
            processPars.parsing_successful = null;
            processPars.object_count = "—";
            processPars.stop_requested = false;
          }

          // 2) Обработка информации о профиле
          if (processPars.profile) {
            const profile = processPars.profile;
            processPars.profile_description = profile.description || "";
            processPars.profile_status =
              profile.status === "logged_in" ? "Залогинен" : "Незалогинен";
          } else {
            processPars.profile_description = "Нет профиля";
            processPars.profile_status = "-";
          }

          return processPars;
        })
      );
      setProcesses(updatedResults || []);
    } catch (err) {
      setError(err);
      setProcesses([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProcesses();
    const interval = setInterval(() => {
      fetchProcesses();
    }, 60000); // Обновляем каждые 60 секунд

    return () => clearInterval(interval);
  }, [user]);

  return { processes, setProcesses, fetchProcesses, loading, error };
}
