import React, {
  useEffect,
  useState,
  useRef,
  useCallback
} from 'react';
import axios from 'axios';
import { Box, Typography, IconButton, Grid, Paper, MenuItem, Select } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';

function getCsrfToken() {
  const cookieValue = document.cookie
    .split('; ')
    .find(row => row.startsWith('csrftoken='))
    ?.split('=')[1];
  return cookieValue;
}

const RESULT_CHOICES = [
  'Не дозвонился',
  'Пропуск диалога',
  'Отказ от диалога',
  'Отказ от встречи',
  'Отдал агентам',
  'Продал сам',
  'Низкая мотивация',
  'Квалифицирован',
  'Лид',
  'Встреча'
];

const ActivityList = ({ subjectId, inWork, onActivityResultChange }) => {
  const [activities, setActivities] = useState([]);
  const [error, setError] = useState(null);
  const [limit] = useState(5);
  const [offset, setOffset] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [isVisible, setIsVisible] = useState(false); // для ленивой подгрузки
  const observer = useRef();
  const containerRef = useRef();

  // Сброс состояния при смене subjectId
  useEffect(() => {
    setActivities([]);
    setOffset(0);
    setHasMore(true);
    setIsVisible(false);
  }, [subjectId]);

  // Intersection Observer для "ленивого" запуска загрузки
  useEffect(() => {
    const currentRef = containerRef.current;
    if (!currentRef) return;

    const observerInstance = new IntersectionObserver(
      (entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            setIsVisible(true);
            observerInstance.unobserve(currentRef);
          }
        });
      },
      {
        root: null,
        threshold: 0.1,
      }
    );

    observerInstance.observe(currentRef);

    return () => {
      if (currentRef) observerInstance.unobserve(currentRef);
    };
  }, []);

  // Загрузка данных при первом появлении и при прокрутке (offset)
  useEffect(() => {
    if (isVisible && subjectId) {
      fetchActivities();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible, offset, subjectId]);

  const fetchActivities = async () => {
    try {
      const resp = await axios.get(`${process.env.REACT_APP_API_URL}/dashboard/api/activities/`, {
        params: { subject: subjectId, limit, offset },
        withCredentials: true,
        headers: {
          'X-CSRFToken': getCsrfToken(),
        },
      });

      const { results, next } = resp.data;

      if (offset === 0) {
        setActivities(results);
      } else {
        setActivities((prev) => [...prev, ...results]);
      }

      if (!next) {
        setHasMore(false);
      }
    } catch (err) {
      console.error('Ошибка при получении активностей:', err);
      // setError('Не удалось загрузить активности');
    }
  };

  // Intersection Observer для подгрузки новых частей
  const lastActivityElementRef = useCallback((node) => {
    if (!node) return;
    if (observer.current) {
      observer.current.disconnect();
    }

    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && hasMore && isVisible) {
        setOffset((prevOffset) => prevOffset + limit);
      }
    });

    observer.current.observe(node);
  }, [hasMore, limit, isVisible]);

  /**
   * При смене результата активности вызываем PATCH и, если нужно,
   * дергаем колбэк onActivityResultChange.
   */
  const handleResultChange = async (activityId, newResult) => {
    try {
      await axios.patch(
        `${process.env.REACT_APP_API_URL}/dashboard/api/activities/${activityId}/`,
        { result: newResult },
        {
          withCredentials: true,
          headers: { 'X-CSRFToken': getCsrfToken() },
        }
      );

      // Обновляем локальный стейт
      setActivities((prev) =>
        prev.map((activity) =>
          activity.id === activityId
            ? { ...activity, result: newResult }
            : activity
        )
      );

      // Вызываем колбэк, чтобы в родителе могли сделать все нужные действия
      if (onActivityResultChange) {
        onActivityResultChange(newResult);
      }
    } catch (err) {
      console.error('Ошибка при обновлении результата активности:', err);
      setError('Не удалось обновить результат активности');
    }
  };

  const handleAddActivity = async () => {
    const moscowTimezone = new Date().toLocaleString('en-US', { timeZone: 'Europe/Moscow' });
    const currentDateTime = new Date(moscowTimezone);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/dashboard/api/activities/`,
        {
          subject: subjectId,
          result: 'Не дозвонился',
          // created_at: currentDateTime.toISOString(),
        },
        {
          withCredentials: true,
          headers: { 'X-CSRFToken': getCsrfToken() },
        }
      );

      const newActivity = response.data;
      setActivities((prev) => [...prev, newActivity]);
    } catch (err) {
      console.error('Ошибка при создании активности:', err);
      setError('Не удалось создать активность');
    }
  };

  if (error) {
    return <p>{error}</p>;
  }

  // Чтобы шли по возрастанию даты
  const sortedActivities = [...activities].sort(
    (a, b) => new Date(a.created_at) - new Date(b.created_at)
  );

  return (
    <Box
      ref={containerRef}
      sx={{
        bgcolor: '#e3f2fd',
        p: 2,
        borderRadius: 1,
        border: '1px solid #cbd5e1',
        mt: 2
      }}
    >
      {/* Заголовок "Активности" + кнопка добавления */}
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 0.5 }}>
        <Typography variant="subtitle1" sx={{ color: '#1e3a8a', fontWeight: 'bold' }}>
          Активности
        </Typography>
        <IconButton color="primary" onClick={handleAddActivity} disabled={!inWork}>
          <AddCircleIcon />
        </IconButton>
      </Box>

      {isVisible ? (
        sortedActivities.length > 0 ? (
          <Grid container spacing={1.5}>
            {sortedActivities.map((activity, index) => {
              const isLastElement = index === sortedActivities.length - 1;
              return (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  lg={2}
                  key={activity.id}
                  ref={isLastElement ? lastActivityElementRef : null}
                >
                  <Paper
                    elevation={1}
                    sx={{
                      p: 1,
                      bgcolor: '#ffffff',
                      borderRadius: 1,
                      border: '1px solid #cbd5e1',
                    }}
                  >
                    <Typography variant="caption" sx={{ display: 'block', mb: 0.5 }}>
                      Дата: {new Date(activity.created_at).toLocaleString('ru-RU')}
                    </Typography>
                    <Select
                      value={activity.result || ''}
                      onChange={(e) => handleResultChange(activity.id, e.target.value)}
                      fullWidth
                      displayEmpty
                      sx={{
                        fontSize: '0.75rem',
                        '& .MuiSelect-select': {
                          padding: '2px 4px',
                        },
                      }}
                    >
                      {RESULT_CHOICES.map((choice) => (
                        <MenuItem key={choice} value={choice}>
                          {choice}
                        </MenuItem>
                      ))}
                    </Select>
                  </Paper>
                </Grid>
              );
            })}
          </Grid>
        ) : (
          <Typography variant="body2" sx={{ mt: 1.5 }}>
            Нет активностей
          </Typography>
        )
      ) : (
        <Typography variant="body2" sx={{ mt: 1.5 }}>
          Активности не загружены. Прокрутите страницу, чтобы загрузить.
        </Typography>
      )}
    </Box>
  );
};

export default ActivityList;
