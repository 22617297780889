import React, { useState } from 'react';
import {
  Dialog, DialogTitle, DialogContent, DialogActions,
  TextField, Button, Snackbar, Alert, Box, Typography
} from '@mui/material';
import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/Logout';

export default function AuthBar({ user, loading, onLogin, onLogout, errorMessage }) {
  const [openDialog, setOpenDialog] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  // Открыть/закрыть диалог логина
  const handleOpenLogin = () => setOpenDialog(true);
  const handleCloseLogin = () => {
    setOpenDialog(false);
    setUsername('');
    setPassword('');
  };

  // Клик по кнопке «Войти»
  const handleLoginClick = () => {
    onLogin(username, password)
      .then(() => {
        handleCloseLogin();
      })
      .catch((err) => {
        // Можно обработать ошибку (например, showSnackbar)
      });
  };

  return (
    <Box>
      {user ? (
        <Box display="flex" alignItems="center">
          <Typography variant="body1" sx={{ marginRight: 2 }}>
            Привет, {user.user?.username}
          </Typography>
          <Button
            variant="contained"
            color="secondary"
            startIcon={<LogoutIcon />}
            onClick={onLogout}
            disabled={loading}
          >
            Выйти
          </Button>
        </Box>
      ) : (
        <Button
          variant="contained"
          color="primary"
          startIcon={<LoginIcon />}
          onClick={handleOpenLogin}
          disabled={loading}
        >
          Войти
        </Button>
      )}

      {/* Диалог входа */}
      <Dialog open={openDialog} onClose={handleCloseLogin}>
        <DialogTitle>Вход в аккаунт</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Логин"
            type="text"
            fullWidth
            variant="outlined"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            disabled={loading}
          />
          <TextField
            margin="dense"
            label="Пароль"
            type="password"
            fullWidth
            variant="outlined"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            disabled={loading}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseLogin} color="secondary" disabled={loading}>
            Отмена
          </Button>
          <Button
            onClick={handleLoginClick}
            color="primary"
            variant="contained"
            disabled={loading || !username || !password}
          >
            {loading ? 'Вход...' : 'Войти'}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Пример Snackbar (если нужно) */}
      {errorMessage && (
        <Snackbar open autoHideDuration={6000}>
          <Alert severity="error" sx={{ width: '100%' }}>
            {errorMessage}
          </Alert>
        </Snackbar>
      )}
    </Box>
  );
}
