// src/components/PublicationsPage/LazyImage.jsx
import React from 'react';
import { useIntersection } from './useIntersection';

function LazyImage({ src, alt, style, placeholderStyle }) {
  const [ref, isVisible] = useIntersection({ threshold: 0.1 });
  return (
    <div ref={ref} style={{ ...placeholderStyle, textAlign: 'center' }}>
      {isVisible ? (
        <img src={src} alt={alt} style={style} />
      ) : (
        // Placeholder можно оставить пустым или добавить спиннер/серый фон
        <div style={{ ...style, backgroundColor: '#f0f0f0' }} />
      )}
    </div>
  );
}

export default LazyImage;
