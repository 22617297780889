// src/components/PublicationsPage/PublicationCard.jsx
import React from 'react';
import { Card, Tag, Typography } from 'antd';
import LazyImage from './LazyImage';

const { Text } = Typography;

function PublicationCard({ publication }) {
  return (
    <div style={{ marginBottom: '16px' }}>
      <Card
        hoverable
        style={{
          borderRadius: '8px',
          border: '1px solid #e0e0e0',
          boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
        }}
        title={
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <span>{publication.news_date}</span>
            <Text type="secondary" style={{ fontSize: '12px' }}>
              Пост №{publication.post_number}
            </Text>
          </div>
        }
        bodyStyle={{ padding: '16px' }}
      >
        <div style={{ marginBottom: '8px' }}>
          <Text strong>Источник:</Text>{' '}
          <Tag color="blue" style={{ fontSize: '14px' }}>
            {publication.source?.name}
          </Tag>
        </div>
        <div style={{ marginBottom: '8px' }}>
          <Text style={{ fontSize: '15px' }}>{publication.text}</Text>
        </div>
        {publication.image_url && (
          <div style={{ marginTop: '10px' }}>
            <LazyImage
              src={publication.image_url}
              alt="Публикация"
              style={{ maxWidth: '100%', maxHeight: '300px', borderRadius: '4px' }}
              placeholderStyle={{ maxWidth: '100%', maxHeight: '300px', borderRadius: '4px' }}
            />
          </div>
        )}
      </Card>
    </div>
  );
}

export default PublicationCard;
