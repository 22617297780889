import React from 'react';
import PromoHeader from './PromoHeader';
import ProblemSection from './ProblemSection';
import SolutionSections from './SolutionSections';
import VideoBlock from './VideoBlock';
import TryItNow from './TryItNow';
import FaqSection from './FaqSection';
import FooterSection from './FooterSection';

export default function HomePage() {
  return (
    <div className="w-full flex flex-col">
      {/* 
        Меню (MenuBar) уже импортируется в App.js 
        Поэтому здесь просто контент промо-страницы 
      */}
      <PromoHeader />
      <ProblemSection />
      <SolutionSections />
      <VideoBlock />
      <TryItNow />
      <FaqSection />
      <FooterSection />
    </div>
  );
}
