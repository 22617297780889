import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle
} from 'react';
import axios from 'axios';
import {
  Box,
  Typography,
  TextField,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Grid,
  Paper,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  CircularProgress,
  IconButton,
  Snackbar,
  Alert,
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import SaveIcon from '@mui/icons-material/Save';
import WorkOffIcon from '@mui/icons-material/WorkOff';

// Справочник меток для чекбоксов
const touchFieldLabels = {
  direct_number: 'Прямой номер',
  alternativa: 'Альтернатива',
  give_to_agents: 'Отдал агентам',
  deposit: 'Внесен задаток',
  deferred: 'Продажа отложена',
  hide: 'Скрыть',
};

// Справочник возможных статусов CRM
const crmStatuses = [
  { value: 'no_status', label: 'Нет статуса' },
  { value: 'primary_contact', label: 'Первичный контакт' },
  { value: 'qualified', label: 'Квалифицирован' },
  { value: 'qualified_plus', label: 'Квалифицирован+' },
  { value: 'lead', label: 'Лид' },
  { value: 'appointment_set', label: 'Назначена встреча' },
  { value: 'appointment_confirmed', label: 'Встреча подтверждена' },
  { value: 'appointment_held', label: 'Встреча состоялась' },
  { value: 'contract_signed', label: 'Договор подписан' },
];

const OwnerDetailsLite = forwardRef(({ subjectId, workStatusData, inWork }, ref) => {
  // Данные клиента
  const [client, setClient] = useState(workStatusData?.client || {});
  // Данные touch
  const [touch, setTouch] = useState(workStatusData?.touch || {});

  // Состояния для процесса сохранения и успешного сохранения
  const [saving, setSaving] = useState(false);
  const [saved, setSaved] = useState(false);

  // Состояние для отображения Snackbar
  const [openSnackbar, setOpenSnackbar] = useState(false);

  // При загрузке новых данных в workStatusData синхронизируем стейты
  useEffect(() => {
    setClient(workStatusData?.client || {});
    setTouch(workStatusData?.touch || {});
  }, [workStatusData]);

  // =========================
  // ЕДИНАЯ ФУНКЦИЯ «updateAndSave»
  // =========================
  /**
   * Параметры (обязательные/необязательные):
   *  - clientChanges: объект с изменениями для client
   *  - touchChanges: объект с изменениями для touch
   *
   * Если какие-то из них не нужны, передаём пустой объект.
   */
  const updateAndSave = async ({ clientChanges = {}, touchChanges = {} }) => {
    try {
      setSaving(true);
      setSaved(false);

      // 1) Сформируем новые объекты (сольём старое состояние + изменения)
      const newClient = { ...client, ...clientChanges };
      const newTouch = { ...touch, ...touchChanges };

      // 2) Обновим стейт
      setClient(newClient);
      setTouch(newTouch);

      // 3) Сформируем payload для отправки
      const payload = {
        name: newClient.name || '',
        direct_phone: newClient.direct_phone || '',
        crm_status: newClient.crm_status || 'no_status',
        touch: {
          direct_number: !!newTouch.direct_number,
          alternativa: !!newTouch.alternativa,
          give_to_agents: !!newTouch.give_to_agents,
          deposit: !!newTouch.deposit,
          deferred: !!newTouch.deferred,
          hide: !!newTouch.hide,
        },
      };

      // 4) Выполним запрос на сервер
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/dashboard/api/subject/${subjectId}/user-status-update/`,
        payload,
        { withCredentials: true }
      );

      // 5) Если всё ок — показываем «Успешно»
      if (response.data.success) {
        setSaved(true);
        setOpenSnackbar(true);
      } else {
        // Если бэкенд вернул что-то странное
        setOpenSnackbar(true);
      }
    } catch (error) {
      console.error('Ошибка при сохранении данных:', error);
      setOpenSnackbar(true);
    } finally {
      setSaving(false);
    }
  };

  // =========================
  // Публичные методы для родителя
  // =========================
  useImperativeHandle(ref, () => ({
    // Вызывается при изменении результата активности (ActivityList)
    handleActivityResultChange(newResult) {
      if (newResult === 'Квалифицирован') {
        updateAndSave({
          clientChanges: { crm_status: 'qualified' },
        });
      }
      else if (newResult === 'Лид') {
        updateAndSave({
          clientChanges: { crm_status: 'lead' },
        });
      }
      else if (newResult === 'Встреча') {
        updateAndSave({
          clientChanges: { crm_status: 'appointment_set' },
        });
      }
      else if (['Отказ от диалога', 'Отказ от встречи', 'Низкая мотивация'].includes(newResult)) {
        updateAndSave({
          clientChanges: { crm_status: 'primary_contact' },
        });
      }
      else if (newResult === 'Отдал агентам') {
        updateAndSave({
          touchChanges: { give_to_agents: true },
        });
      }
      // Остальные результаты нас не интересуют
    },
  }));

  // =========================
  // Обработчики для формы (ручное редактирование)
  // =========================
  const handleClientChange = (field, value) => {
    setClient((prev) => ({ ...prev, [field]: value }));
  };

  const handleTouchChange = (field) => {
    setTouch((prev) => ({ ...prev, [field]: !prev[field] }));
  };

  // Этот метод вызывает «универсальную» функцию, без дополнительных изменений,
  // просто сохраняет то, что уже лежит в стейте.
  const handleManualSave = () => {
    updateAndSave({});
  };

  // Закрытие Snackbar
  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  // Если объект не в работе — показываем заглушку
  if (!inWork) {
    return (
      <Paper
        elevation={2}
        sx={{
          borderRadius: 2,
          padding: 4,
          backgroundColor: '#f3f4f6',
          fontSize: '0.8rem',
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
        }}
      >
        <WorkOffIcon sx={{ fontSize: 80, color: '#9ca3af', mb: 2 }} />
        <Typography variant="h6" sx={{ color: '#374151', textAlign: 'center' }}>
          Объект пока не взят в работу
        </Typography>
      </Paper>
    );
  }

  // =========================
  // Рендер формы
  // =========================
  return (
    <Paper
      elevation={2}
      sx={{
        borderRadius: 2,
        padding: 2,
        backgroundColor: '#f3f4f6',
        fontSize: '0.8rem',
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {/* Заголовок и кнопка сохранения */}
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography
          variant="subtitle1"
          gutterBottom
          sx={{ color: '#374151', fontWeight: 'bold' }}
        >
          Данные о клиенте
        </Typography>

        {/* Кнопка сохранения + индикаторы */}
        <Box display="flex" alignItems="center">
          <IconButton
            color="primary"
            onClick={handleManualSave}
            disabled={saving}
            sx={{ padding: '4px' }}
          >
            <SaveIcon />
          </IconButton>
          {saving && <CircularProgress size={24} sx={{ marginLeft: '8px' }} />}
          {saved && <CheckIcon color="success" sx={{ marginLeft: '8px' }} />}
        </Box>
      </Box>

      <Grid container spacing={2}>
        {/* Поле "Имя" */}
        <Grid item xs={12} sm={6}>
          <TextField
            label="Имя"
            variant="outlined"
            size="small"
            value={client?.name || ''}
            onChange={(e) => handleClientChange('name', e.target.value)}
            fullWidth
            sx={{
              backgroundColor: '#fff',
              borderRadius: 1,
              '& .MuiInputLabel-root': { fontSize: '0.75rem' },
              '& .MuiInputBase-input': { fontSize: '0.75rem' },
            }}
          />
        </Grid>

        {/* Поле "Телефон" */}
        <Grid item xs={12} sm={6}>
          <TextField
            label="Телефон"
            variant="outlined"
            size="small"
            value={client?.direct_phone || ''}
            onChange={(e) => handleClientChange('direct_phone', e.target.value)}
            fullWidth
            sx={{
              backgroundColor: '#fff',
              borderRadius: 1,
              '& .MuiInputLabel-root': { fontSize: '0.75rem' },
              '& .MuiInputBase-input': { fontSize: '0.75rem' },
            }}
          />
        </Grid>
      </Grid>

      {/* Поле выбора CRM статуса */}
      <Box mt={2}>
        <FormControl size="small" fullWidth>
          <InputLabel sx={{ fontSize: '0.75rem' }}>CRM статус</InputLabel>
          <Select
            label="CRM статус"
            value={client?.crm_status || 'no_status'}
            onChange={(e) => handleClientChange('crm_status', e.target.value)}
            sx={{
              backgroundColor: '#fff',
              borderRadius: 1,
              '& .MuiInputBase-input': { fontSize: '0.75rem' },
              '& .MuiInputLabel-root': { fontSize: '0.75rem' },
            }}
          >
            {crmStatuses.map((item) => (
              <MenuItem key={item.value} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      {/* Чекбоксы touch */}
      <Box mt={2}>
        <FormGroup>
          <Grid container spacing={1}>
            {Object.entries(touchFieldLabels).map(([field, label]) => (
              <Grid item xs={4} key={field}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={Boolean(touch[field])}
                      onChange={() => handleTouchChange(field)}
                      sx={{
                        padding: '4px',
                        '& .MuiSvgIcon-root': { fontSize: 16 },
                        color: touch[field] ? '#1d4ed8' : '#9ca3af',
                        '&.Mui-checked': {
                          color: '#1d4ed8',
                        },
                      }}
                    />
                  }
                  label={label}
                  sx={{
                    '& .MuiFormControlLabel-label': { fontSize: '0.7rem' },
                  }}
                />
              </Grid>
            ))}
          </Grid>
        </FormGroup>
      </Box>

      {/* Snackbar для уведомлений о сохранении */}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        {saved ? (
          <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
            Данные успешно сохранены!
          </Alert>
        ) : (
          <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
            Ошибка при сохранении данных.
          </Alert>
        )}
      </Snackbar>
    </Paper>
  );
});

export default OwnerDetailsLite;
