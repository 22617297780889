import React from 'react';

export default function FaqSection() {
  return (
    <div className="w-full bg-white py-10">
      <div className="container mx-auto px-4">
        <h2 className="text-2xl md:text-4xl font-bold text-blue-900 mb-6">
          Часто задаваемые вопросы
        </h2>
        
        <div className="mb-4">
          <h3 className="text-lg font-semibold text-blue-700">
            1. Когда появится возможность работать с другими городами?
          </h3>
          <p className="text-gray-700">
            Мы планируем подключать города поэтапно, начиная с Иркутска, далее — 
            Москва и другие города. Оставьте заявку, чтобы получить уведомление!
          </p>
        </div>

        <div className="mb-4">
          <h3 className="text-lg font-semibold text-blue-700">
            2. Сколько стоит доступ к сервису?
          </h3>
          <p className="text-gray-700">
            В данный момент услуга предоставляется в формате бета-доступа. 
            Тарифы будут пересмотрены в дальнейшем, но сейчас всего 20 рублей за одну запись.
          </p>
        </div>

        <div className="mb-4">
          <h3 className="text-lg font-semibold text-blue-700">
            3. Могу ли я отменить подписку в любой момент?
          </h3>
          <p className="text-gray-700">
            Да, вы можете отменить подписку в любой момент. Детали уточняйте у службы поддержки.
          </p>
        </div>
      </div>
    </div>
  );
}
