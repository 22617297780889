// components/statusLabels.js
export const statusLabels = {
    "Started": "Старт",
    "In Progress": "В процессе",
    "Completed": "Завершено",
    "Failed": "Завершено с ошибкой",
    "Resume": "Возобновлен",
    "Stopped": "Остановлен",
  };
  
  export const statusColors = {
    "Started": "blue",
    "In Progress": "processing",
    "Completed": "green",
    "Failed": "red",
    "Resume": "purple",
    "Stopped": "grey",
  };
  