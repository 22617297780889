// src/components/RouteDataCollection.js
import React from 'react';
import { Layout } from 'antd';
import ParserSelect from './ParserSelect';
import ProcessManager from "./ProcessManager/ProcessManager";

const { Header, Content } = Layout;

const RouteDataCollection = ({ user }) => {
  return (
    <Layout>

      <Content style={{ padding: '7px' }}>
        {/* Передаём user в нужные внутренние компоненты, если они зависят от авторизации */}
        <ParserSelect user={user} />
        <ProcessManager user={user} />
        {/* <ProcessList user={user} /> */}
      </Content>
    </Layout>
  );
};

export default RouteDataCollection;
