import React from 'react';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Autocomplete,
  TextField,
  Grid,
  Box, 
  Button,
} from '@mui/material';

const CityDistrictFilter = ({
  selectedCity,
  setSelectedCity,
  cityOptions,
  selectedDistricts,
  setSelectedDistricts,
  districts,
  handleReset,
}) => {
  const handleCityChange = (e) => setSelectedCity(e.target.value);

  const handleDistrictChange = (event, newValue) => {
    const keys = newValue.map(item => item.key);
    if (keys.includes('all')) {
      setSelectedDistricts([]);
    } else {
      setSelectedDistricts(keys);
    }
  };

  return (
    <>
      {/* Фильтр по городу */}
      <Grid item xs={12} md={4}>
        <FormControl fullWidth size="small">
          <InputLabel id="city-label">Город</InputLabel>
          <Select
            labelId="city-label"
            value={selectedCity}
            label="Город"
            onChange={handleCityChange}
          >
            {/* <MenuItem value="all">Все доступные города</MenuItem> */}
            {cityOptions.map(city => (
              <MenuItem key={city.key} value={city.key}>
                {city.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>

      {/* Фильтр по районам */}
      <Grid item xs={12} md={4}>
        {districts.length > 0 && (
          <Autocomplete
            multiple
            size="small"
            options={[{ key: 'all', name: 'Все районы' }, ...districts]}
            getOptionLabel={(option) => option.name}
            value={
              selectedDistricts.includes('all')
                ? [{ key: 'all', name: 'Все районы' }]
                : districts.filter(d => selectedDistricts.includes(d.key))
            }
            onChange={handleDistrictChange}
            renderInput={(params) => (
              <TextField {...params} label="Районы" variant="outlined" size="small" />
            )}
          />
        )}
      </Grid>
      <Grid item xs={12} md={4}>
        <Box display="flex" width="100%">
          <Button onClick={handleReset} variant="outlined" color="secondary">
            Сбросить фильтр
          </Button>
        </Box>
      </Grid>
    </>
  );
};

export default CityDistrictFilter;
