import React from 'react';
import { Box, Typography, Link, Grid, Paper } from '@mui/material';
import DuplicatesButton from './DuplicatesButton';

const CardDetails = ({ subject }) => {
  return (
    <Paper
      elevation={2}
      sx={{
        borderRadius: 2,
        padding: 2,
        backgroundColor: '#f3f4f6',
        fontSize: '0.8rem',  // Компактный размер шрифта
        flex: 1,              // Растягиваем Paper по высоте родителя
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Typography
        variant="subtitle1"
        gutterBottom
        sx={{
          color: '#374151',
          fontWeight: 'bold',
        }}
      >
      </Typography>

      <Grid container spacing={2} sx={{ color: '#374151' }}>
        {/* Адрес */}
        <Grid item xs={12}>
          <Typography
            sx={{
              fontSize: '0.95rem',
              color: '#2563eb',
              fontWeight: 'bold',
            }}
          >
            Адрес: {subject.adress || 'Не указан'}
          </Typography>
        </Grid>

        {/* Площадь */}
        {/* <Grid item xs={12} sm={4}>
          <Typography sx={{ fontSize: '0.9rem' }}>
            Площадь:{' '}
            <span style={{ fontWeight: '500' }}>
              {subject.square || 'N/A'} м²
            </span>
          </Typography>
        </Grid> */}

        {/* Комнаты */}
        {/* <Grid item xs={12} sm={4}>
          <Typography sx={{ fontSize: '0.9rem' }}>
            Комнат:{' '}
            <span style={{ fontWeight: '500' }}>
              {subject.rooms || 'N/A'}
            </span>
          </Typography>
        </Grid> */}

        {/* Цена */}
        <Grid item xs={12} sm={4}>
          <Typography sx={{ fontSize: '1rem' }}>
            Цена:{' '}
            <span style={{ fontWeight: '800', color: '#2563eb' }}>
              {subject.price || 'N/A'} ₽
            </span>
          </Typography>
        </Grid>

        {/* Цена за квадрат */}
        <Grid item xs={12} sm={4}>
          <Typography sx={{ fontSize: '0.9rem' }}>
            Цена м²:{' '}
            <span style={{ fontWeight: '500', color: '#dc2626' }}>
              {subject.price_meter || 'N/A'} ₽/м²
            </span>
          </Typography>
        </Grid>

        {/* Район */}
        <Grid item xs={12} sm={4}>
          <Typography sx={{ fontSize: '0.9rem' }}>
            Район:{' '}
            <span style={{ fontWeight: '500' }}>
              {subject.district || 'Не указан'}
            </span>
          </Typography>
        </Grid>

        {/* Продавец */}
        <Grid item xs={12} sm={4}>
          <Typography sx={{ fontSize: '0.9rem' }}>
            Продавец:{' '}
            {subject.seller_type ? (
              <Link
                href={subject.seller_link}
                target="_blank"
                rel="noopener noreferrer"
                sx={{
                  fontWeight: '500',
                  color: '#2563eb',
                  textDecoration: 'none',
                  '&:hover': { textDecoration: 'underline' },
                }}
              >
                {subject.seller_type}
              </Link>
            ) : (
              <span style={{ fontWeight: '500' }}>
                {subject.seller_type || 'Нет данных'}
              </span>
            )}
          </Typography>
        </Grid>
      </Grid>

      {/* Кнопка на всю ширину */}
      <Box mt={2}>
        <DuplicatesButton subjectId={subject.id} />
      </Box>
    </Paper>
  );
};

export default CardDetails;
