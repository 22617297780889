// src/components/PublicationsPage/PublicationsList.jsx
import React from 'react';
import { List } from 'antd';
import PublicationCard from './PublicationCard';

function PublicationsList({ publications, loading }) {
  return (
    <div style={{ }}>
      <List
        dataSource={publications}
        loading={loading}
        renderItem={(publication) => (
          <PublicationCard key={publication.id} publication={publication} />
        )}
      />
      {!loading && publications.length === 0 && (
        <p style={{ fontStyle: 'italic', textAlign: 'center', marginTop: 8 }}>
          Нет публикаций
        </p>
      )}
    </div>
  );
}

export default PublicationsList;
