import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Box,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import AuthBar from './LITE/AuthBar';

export default function MenuBar({ user, loading, errorMessage, onLogin, onLogout }) {
  // Состояние для открытия/закрытия мобильного меню (Drawer)
  const [drawerOpen, setDrawerOpen] = useState(false);

  // Переключатель состояния Drawer
  const handleDrawerToggle = () => {
    setDrawerOpen((prev) => !prev);
  };

  // Формируем общий список пунктов меню
  const menuItems = [
    { label: 'Главная', to: '/' },
    { label: 'Объекты', to: '/lite_subjects' },
  ];

  if (user?.role === 'admin') {
    menuItems.push({ label: 'Нормализация', to: '/normalize-addresses' });
    menuItems.push({ label: 'Сбор данных', to: '/routeDataCollection' });
    menuItems.push({ label: 'Публикации', to: '/publications' });
  }

  return (
    <AppBar position="static">
      <Toolbar>

        {/* Кнопка «гамбургер», видна только на маленьких экранах */}
        <IconButton
          color="inherit"
          edge="start"
          sx={{ mr: 2, display: { md: 'none' } }}
          onClick={handleDrawerToggle}
        >
          <MenuIcon />
        </IconButton>

        {/* Заголовок/логотип — скрыт на малых экранах, виден на средних и выше */}
        <Typography variant="h6" sx={{ flexGrow: 1, display: { xs: 'none', md: 'block' } }}>
          SOCOL24
        </Typography>

        {/* Горизонтальное меню (для средних/больших экранов) */}
        <Box sx={{ display: { xs: 'none', md: 'flex' }, flexGrow: 1 }}>
          {menuItems.map((item) => (
            <Button 
              key={item.to}
              color="inherit" 
              component={Link} 
              to={item.to}
            >
              {item.label}
            </Button>
          ))}
        </Box>

        {/* Блок авторизации (AuthBar) */}
        <Box sx={{ marginLeft: 2 }}>
          <AuthBar
            user={user}
            loading={loading}
            errorMessage={errorMessage}
            onLogin={onLogin}
            onLogout={onLogout}
          />
        </Box>
      </Toolbar>

      {/* Мобильное меню (Drawer), виден только на маленьких экранах */}
      <Drawer
        anchor="left"
        open={drawerOpen}
        onClose={handleDrawerToggle}
        sx={{ display: { md: 'none' } }}
      >
        <Box
          sx={{ width: 250 }}
          role="presentation"
          onClick={handleDrawerToggle}
          onKeyDown={handleDrawerToggle}
        >
          <List>
            {/* Заголовок (можно убрать или заменить логотипом) */}
            <ListItem>
              <Typography variant="h6">SOCOL24</Typography>
            </ListItem>

            {/* Ссылки меню */}
            {menuItems.map((item) => (
              <ListItem key={item.to} disablePadding>
                <ListItemButton component={Link} to={item.to}>
                  <ListItemText primary={item.label} />
                </ListItemButton>
              </ListItem>
            ))}
            
            {/* При желании можно добавить сюда кнопки для авторизации/выхода */}
          </List>
        </Box>
      </Drawer>
    </AppBar>
  );
}
