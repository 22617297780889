import React, { useState } from 'react';
import axios from 'axios';

const WorkStatus = ({ subjectId, workStatusData, onClientUpdated }) => {
  const inWork = workStatusData?.in_work || false;
  const daysInWork = workStatusData?.days_in_work || 0;
  const [loading, setLoading] = useState(false);

  const takeInWork = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/dashboard/api/take-in-work/${subjectId}/`,
        {},
        { withCredentials: true }
      );

      if (response.data.success) {
        onClientUpdated?.();  // Перезапрашиваем данные через LazyDataProvider
      }
    } catch (error) {
      console.error('Ошибка при взятии объекта в работу:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
      {inWork ? (
        <p style={{ color: '#fff', fontWeight: 'bold', fontSize: '1rem', margin: 0 }}>
          В работе {daysInWork} д.
        </p>
      ) : (
        <button
          onClick={takeInWork}
          disabled={loading}
          style={{
            padding: '8px 16px',
            backgroundColor: loading ? '#6B7280' : '#1D4ED8',
            color: '#fff',
            borderRadius: '4px',
            border: 'none',
            cursor: loading ? 'not-allowed' : 'pointer',
          }}
        >
          {loading ? 'Загрузка...' : 'Взять в работу'}
        </button>
      )}
    </div>
  );
};

export default WorkStatus;
