// src/components/EnrichAddresses.js
import React, { useState, useEffect } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { message, Button, Select, Input, Card } from "antd";

const EnrichAddresses = ({ user, onBack }) => {
  const [cities, setCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState("");
  const [count, setCount] = useState("all");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/dashboard/api/cities/`, {
        withCredentials: true,
      })
      .then((res) => {
        if (res.data && res.data.results) {
          setCities(res.data.results);
        }
      })
      .catch((error) => {
        console.error("Ошибка при получении списка городов:", error);
        message.error("Не удалось загрузить города.");
      });
  }, []);

  const handleStartEnrichment = () => {
    if (!user) {
      message.warning("Сначала войдите в систему (пользователь не аутентифицирован).");
      return;
    }
    if (!selectedCity) {
      message.warning("Выберите город из списка.");
      return;
    }

    setLoading(true);

    axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/start-address-enrichment/`,
        {
          city_name: selectedCity,
          count: count,
        },
        {
          headers: {
            "X-CSRFToken": Cookies.get("csrftoken") || "",
          },
          withCredentials: true,
        }
      )
      .then((response) => {
        message.success(response.data.message || "Процесс обогащения запущен!");
      })
      .catch((error) => {
        if (error.response) {
          const { status, data } = error.response;
          console.error("Ошибка при запуске обогащения:", status, data);
          if (data && data.error) {
            message.error(`Ошибка: ${data.error}`);
          } else {
            message.error("Ошибка при запуске обогащения. Код " + status);
          }
        } else {
          message.error("Ошибка при запуске обогащения (нет ответа).");
          console.error(error);
        }
      })
      .finally(() => setLoading(false));
  };

  // Центрированный контейнер с градиентным фоном, скруглёнными углами и тенями
  const containerStyle = {
    maxWidth: "400px",
    margin: "40px auto",
    padding: "30px",
    background: "linear-gradient(135deg, #ffffff, #e0f7fa)",
    borderRadius: "12px",
    boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
    textAlign: "center",
  };

  const formItemStyle = { marginBottom: "16px", width: "100%" };

  return (
    <Card style={containerStyle} bordered={false}>
      <h2 style={{ color: "#00796b", marginBottom: "24px" }}>Обогащение адресов</h2>

      <div style={formItemStyle}>
        <Select
          style={{ width: "100%" }}
          placeholder="Выберите город"
          value={selectedCity}
          onChange={(val) => setSelectedCity(val)}
        >
          {cities.map((city) => (
            <Select.Option key={city.id} value={city.name}>
              {city.name}
            </Select.Option>
          ))}
        </Select>
      </div>

      <div style={formItemStyle}>
        <Input
          style={{ width: "100%" }}
          placeholder="Количество адресов (число или 'all')"
          value={count}
          onChange={(e) => setCount(e.target.value)}
        />
      </div>

      <div style={formItemStyle}>
        <Button type="primary" onClick={handleStartEnrichment} loading={loading} block>
          Запустить обогащение
        </Button>
      </div>

      <div style={formItemStyle}>
        <Button onClick={onBack} block>
          Назад
        </Button>
      </div>
    </Card>
  );
};

export default EnrichAddresses;
