// src/components/PublicationsPage/useIntersection.js
import { useState, useEffect, useRef } from 'react';

/**
 * useIntersection - возвращает ref и флаг isIntersecting=true, когда ref-элемент появляется в зоне видимости
 */
export function useIntersection(options) {
  const [isIntersecting, setIsIntersecting] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    const el = ref.current;
    if (!el) return;

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsIntersecting(true);
          observer.unobserve(el); // перестаём наблюдать, если нужно только 1 раз
        }
      },
      { threshold: 0.1, ...options }
    );

    observer.observe(el);

    return () => {
      observer.disconnect();
    };
  }, [options]);

  return [ref, isIntersecting];
}
