import React, {
  memo,
  useState,
  useRef
} from 'react';
import { Box } from '@mui/material';

import CardHeader from './CardHeader';
import CardDetails from './CardDetails';

// Обратите внимание: теперь OwnerDetailsLite — это forwardRef-компонент (см. ниже)
import OwnerDetailsLite from './OwnerDetailsLite';

import LazyDataProvider from './LazyDataProvider';
import HistorySection from './HistorySection';
import ContactInterval from './ContactInterval';
import ActivityList from './ActivityListLite';
import CommentSection from './ComentLite';

const ObjectDetailsLite = memo(({ subject }) => {
  const [workStatusData, setWorkStatusData] = useState(null);
  const lazyProviderRef = useRef(null);

  // Реф на OwnerDetailsLite — нужен, чтобы вызывать тамошний публичный метод
  const ownerDetailsRef = useRef(null);

  const handleDataLoaded = (newData) => {
    setWorkStatusData(newData);
  };

  const handleClientUpdated = () => {
    lazyProviderRef.current?.reloadData();
  };

  return (
    <Box
      sx={{
        border: '2px solid #ddd',
        borderRadius: 2,
        padding: 2,
        marginBottom: 2,
      }}
    >
      <CardHeader
        subject={subject}
        workStatusData={workStatusData}
        onClientUpdated={handleClientUpdated}
      />

      <LazyDataProvider
        ref={lazyProviderRef}
        subjectId={subject.id}
        onDataLoaded={handleDataLoaded}
      >
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'nowrap',
            width: '100%',
            gap: 2,
            marginTop: 2,
            alignItems: 'stretch',
          }}
        >
          <Box
            sx={{
              flex: 1,
              minWidth: 0,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <CardDetails subject={subject} />
          </Box>

          <Box
            sx={{
              flex: 1,
              minWidth: 0,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <OwnerDetailsLite
              ref={ownerDetailsRef}  // прикрепляем ref
              subjectId={subject.id}
              workStatusData={workStatusData}
              inWork={workStatusData?.in_work}
            />
          </Box>
        </Box>
      </LazyDataProvider>

      {/* Второй блок: история + контактный интервал */}
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'nowrap',
          width: '100%',
          gap: 2,
          marginTop: 2,
          alignItems: 'stretch',
          height: '210px',
        }}
      >
        <Box
          sx={{
            flex: 1,
            minWidth: 0,
            display: 'flex',
            flexDirection: 'column',
            minHeight: 0,
          }}
        >
          <HistorySection subjectId={subject.id} />
        </Box>
        <Box
          sx={{
            flex: 1,
            minWidth: 0,
            display: 'flex',
            flexDirection: 'column',
            minHeight: 0,
          }}
        >
          <ContactInterval subjectId={subject.id} />
        </Box>
      </Box>

      {/* Третий блок: активности */}
      <Box>
        <ActivityList
          subjectId={subject.id}
          inWork={workStatusData?.in_work}
          onActivityResultChange={(newResult) => {
            // При смене статуса активности вызываем метод в OwnerDetailsLite (через ref)
            if (ownerDetailsRef.current) {
              ownerDetailsRef.current.handleActivityResultChange(newResult);
            }
          }}
        />
      </Box>

      {/* Комментарии */}
      <Box>
        <CommentSection subjectId={subject.id} />
      </Box>
    </Box>
  );
});

export default ObjectDetailsLite;
