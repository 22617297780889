// tables/ParsersTable.jsx
import React, { useState, useEffect } from "react";
import { Table, Tag, Tooltip, message } from "antd";
import axios from "axios";
import Cookies from "js-cookie";

import { StopOutlined, PlayCircleOutlined } from "@ant-design/icons";

import { useProcesses } from "../hooks/useProcesses";
import { useProfiles } from "../hooks/useProfiles";

// Компоненты + словари
import { portalIcons } from "../components/portalIcons";
import { typeIcons } from "../components/typeIcons";
import { statusLabels, statusColors } from "../components/statusLabels";
import { parsingStatusLabels, parsingStatusColors } from "../components/parsingStatusLabels";

import LogsButtons from "../components/LogsButtons";
import ProfileSelect from "../components/ProfileSelect";
import StageProgress from "../components/StageProgress";

const getCsrfToken = () => Cookies.get("csrftoken") || "";

const ParsersTable = ({ user }) => {


  

  const { processes, setProcesses, fetchProcesses } = useProcesses(user);
  // Фильтруем процессы по типу "Вторичка"
  const filteredProcesses = processes.filter(
    (process) => process.type === "Вторичка"
  );
  const { profiles } = useProfiles(user);

  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [expandedLogs, setExpandedLogs] = useState({});
  const [resumedIds, setResumedIds] = useState([]);

  // Когда user меняется на null (не залогинен), таблица должна очиститься
  // useProcesses уже сам это делает, но на всякий случай можно проверять user
  useEffect(() => {
    if (!user) {
      setExpandedRowKeys([]);
      setExpandedLogs({});
      setResumedIds([]);
    }
  }, [user]);

  // Автообновление логов для развернутых строк
  useEffect(() => {
    if (expandedRowKeys.length === 0) return;
    const interval = setInterval(async () => {
      for (const recordKey of expandedRowKeys) {
        const record = processes.find((p) => p.id === recordKey);
        if (record && record.parsing_process) {
          const logsState = expandedLogs[recordKey];
          if (logsState) {
            // Если stdout открыт, обновляем
            if (logsState.stdout) {
              const newStdoutContent = await fetchLogContent(record.parsing_process, "stdout");
              setExpandedLogs((prev) => ({
                ...prev,
                [recordKey]: {
                  ...prev[recordKey],
                  stdoutContent: newStdoutContent,
                },
              }));
            }
            // Если stderr открыт, обновляем
            if (logsState.stderr) {
              const newStderrContent = await fetchLogContent(record.parsing_process, "stderr");
              setExpandedLogs((prev) => ({
                ...prev,
                [recordKey]: {
                  ...prev[recordKey],
                  stderrContent: newStderrContent,
                },
              }));
            }
          }
        }
      }
    }, 5000);
    return () => clearInterval(interval);
  }, [expandedRowKeys, expandedLogs, processes]);

  const fetchLogContent = async (parsingProcessId, type) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/parsing-processes/${parsingProcessId}/logs/?type=${type}`
      );
      return response.data.content;
    } catch (error) {
      console.error(`Ошибка при загрузке ${type} логов:`, error);
      return "Ошибка загрузки логов";
    }
  };

  const toggleLog = async (record, logType) => {
    const recordKey = record.id;
    const currentState = expandedLogs[recordKey] || {
      stdout: false,
      stderr: false,
      stdoutContent: "",
      stderrContent: "",
    };

    const isCurrentlyOpen = currentState[logType];
    if (isCurrentlyOpen) {
      // Закрываем конкретный лог
      const newState = { ...currentState, [logType]: false };
      setExpandedLogs((prev) => ({ ...prev, [recordKey]: newState }));
      // Если оба лога закрыты - убираем из expandedRowKeys
      if (!newState.stdout && !newState.stderr) {
        setExpandedRowKeys(expandedRowKeys.filter((key) => key !== recordKey));
      }
    } else {
      // Открываем лог
      if (!record.parsing_process) {
        console.error("Нет связанного ParsingProcess для данного процесса");
        return;
      }
      const content = await fetchLogContent(record.parsing_process, logType);
      const contentField = logType === "stdout" ? "stdoutContent" : "stderrContent";
      const newState = {
        ...currentState,
        [logType]: true,
        [contentField]: content,
      };
      setExpandedLogs((prev) => ({ ...prev, [recordKey]: newState }));
      // Добавляем в expandedRowKeys если не было
      if (!expandedRowKeys.includes(recordKey)) {
        setExpandedRowKeys([...expandedRowKeys, recordKey]);
      }
    }
  };

  const toggleStopRequested = async (record) => {
    const newStopRequestedValue = !record.stop_requested;
    try {
      // Локально ставим
      setProcesses((prev) =>
        prev.map((p) => (p.id === record.id ? { ...p, stop_requested: newStopRequestedValue } : p))
      );
      // PATCH
      await axios.patch(
        `${process.env.REACT_APP_API_URL}/api/parsing_objects/${record.parsing_process}/update_status/`,
        { stop_requested: newStopRequestedValue }
      );
      fetchProcesses();
    } catch (error) {
      console.error("Ошибка при обновлении stop_requested:", error);
      // откат
      setProcesses((prev) =>
        prev.map((p) => (p.id === record.id ? { ...p, stop_requested: !newStopRequestedValue } : p))
      );
    }
  };

  const resumeParsing = async (record) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/resume-process/`,
        { process_id: record.id },
        {
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": getCsrfToken(),
          },
          withCredentials: true,
        }
      );
      message.success(response.data.message || "Процесс возобновлён");
      setResumedIds((prev) => [...prev, record.id]);
      fetchProcesses();
    } catch (error) {
      message.error("Ошибка при возобновлении процесса");
      console.error("resumeParsing error:", error);
    }
  };

  const handleProfileChange = async (record, newProfileId) => {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_API_URL}/api/process-runs/${record.id}/update_profile/`,
        { profile: newProfileId },
        {
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": getCsrfToken(),
          },
          withCredentials: true,
        }
      );
      message.success("Профиль успешно изменён");
      const updatedProcess = response.data;
      setProcesses((prev) =>
        prev.map((item) => {
          if (item.id === updatedProcess.id) {
            return {
              ...item,
              profile: updatedProcess.profile,
              profile_description: updatedProcess.profile
                ? updatedProcess.profile.description
                : "Нет профиля",
              profile_status: updatedProcess.profile
                ? updatedProcess.profile.status === "logged_in"
                  ? "Залогинен"
                  : "Незалогинен"
                : "-",
            };
          }
          return item;
        })
      );
    } catch (error) {
      message.error("Ошибка при изменении профиля");
      console.error("Ошибка при handleProfileChange:", error);
      fetchProcesses();
    }
  };

  // Расширенный рендер (логи)
  const expandedRowRender = (record) => {
    const logsState = expandedLogs[record.id];
    if (!logsState || (!logsState.stdout && !logsState.stderr)) return null;

    return (
      <div
        style={{
          whiteSpace: "pre-wrap",
          padding: 10,
          backgroundColor: "#f9f9f9",
          fontSize: 12,
        }}
      >
        {logsState.stdout && (
          <div style={{ marginBottom: 20 }}>
            <h3>STDOUT LOG</h3>
            <div>{logsState.stdoutContent}</div>
          </div>
        )}
        {logsState.stderr && (
          <div>
            <h3>STDERR LOG</h3>
            <div style={{ color: "#b00" }}>{logsState.stderrContent}</div>
          </div>
        )}
      </div>
    );
  };

  // Столбцы таблицы
  const columns = [
    {
      title: "Дата начала",
      dataIndex: "start_time",
      key: "start_time",
      align: "center",
      render: (text) => {
        if (!text) return "Нет данных";
        const dateObj = new Date(text);
        const dateStr = dateObj.toLocaleDateString();
        const timeStr = dateObj.toLocaleTimeString();
        return (
          <div style={{ fontSize: 12, lineHeight: 1.2 }}>
            <div>{dateStr}</div>
            <div>{timeStr}</div>
          </div>
        );
      },
    },
    {
      title: "Город",
      dataIndex: "city",
      key: "city",
      align: "center",
    },
    {
      title: "Портал",
      dataIndex: "portal",
      key: "portal",
      align: "center",
      render: (portalValue) => {
        if (portalIcons[portalValue]) {
          return <Tooltip title={portalValue}>{portalIcons[portalValue]}</Tooltip>;
        }
        return portalValue || "Нет данных";
      },
    },
    {
      title: "Тип",
      dataIndex: "type",
      key: "type",
      align: "center",
      render: (typeValue) => {
        if (typeIcons[typeValue]) {
          return <Tooltip title={typeValue}>{typeIcons[typeValue]}</Tooltip>;
        }
        return typeValue || "Нет данных";
      },
    },
    {
      title: "Статус",
      dataIndex: "status",
      key: "status",
      align: "center",
      render: (statusValue) => (
        <Tag color={statusColors[statusValue] || "default"}>
          {statusLabels[statusValue] || "Нет данных"}
        </Tag>
      ),
    },
    {
      title: "Статус парсинга",
      dataIndex: "parsing_status",
      key: "parsing_status",
      align: "center",
      render: (value, record) => {
        const isResumed = resumedIds.includes(record.id);
        return (
          <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            <StopOutlined
              style={{
                marginRight: 8,
                cursor: "pointer",
                color: record.stop_requested ? "gray" : "blue",
                fontSize: 16,
              }}
              onClick={() => toggleStopRequested(record)}
            />
            <PlayCircleOutlined
              style={{
                marginRight: 8,
                cursor: "pointer",
                color: isResumed ? "green" : "gray",
                fontSize: 16,
              }}
              onClick={() => resumeParsing(record)}
            />
            <Tag color={parsingStatusColors[record.parsing_status_raw] || "default"}>
              {value || "Нет данных"}
            </Tag>
            <LogsButtons
              record={record}
              expandedLogs={expandedLogs}
              toggleLog={toggleLog}
            />
          </div>
        );
      },
    },
    {
      title: "Объявлений",
      dataIndex: "object_count",
      key: "object_count",
      align: "center",
    },
    {
      title: "Профиль",
      key: "profile",
      align: "center",
      render: (_, record) => (
        <ProfileSelect
          record={record}
          profiles={profiles}
          handleProfileChange={handleProfileChange}
        />
      ),
    },
    {
      title: "Этапы",
      dataIndex: "current_stage",
      key: "current_stage",
      align: "center",
      render: (stage) => <StageProgress currentStage={stage} />,
    },
  ];
  
  return (
    <div style={{ overflowX: "auto" }}>
      <Table
        columns={columns}
        dataSource={filteredProcesses}
        rowKey="id"
        pagination={{ pageSize: 10 }}
        expandedRowKeys={expandedRowKeys}
        onExpandedRowsChange={setExpandedRowKeys}
        expandedRowRender={expandedRowRender}
        showExpandColumn={false}
        scroll={{ x: "max-content" }}
        style={{ width: "100%" }}
      />
    </div>
  );
  
};

export default ParsersTable;
