import React from 'react';
import { Grid, Box, TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';

const RoomsAutocomplete = ({ roomsOptions, selectedRooms, handleRoomChange }) => {
  // Преобразуем массив комнат в формат [{ key, label }]
  const options = roomsOptions.map((room) => ({
    key: room,
    label: room,
  }));

  // Для отображения выбранных значений выбираем объекты, ключи которых есть в selectedRooms
  const selectedOptions = options.filter((option) =>
    selectedRooms.includes(option.key)
  );

  // Обработчик изменения: извлекаем из выбранных объектов массив ключей
  const handleChange = (event, value) => {
    const newSelectedRooms = value.map((option) => option.key);
    handleRoomChange(newSelectedRooms);
  };

  return (
    <Grid item xs={12} sm={4}>
      <Box display="flex" flexDirection="column">
        {/* <Box mb={0.5} fontWeight="bold" fontSize="0.875rem">
          Количество комнат
        </Box> */}
        <Autocomplete
          multiple
          size="small"
          options={options}
          getOptionLabel={(option) => option.label}
          value={selectedOptions}
          onChange={handleChange}
          renderInput={(params) => (
            <TextField {...params} label="Количество комнат" variant="outlined" />
          )}
        />
      </Box>
    </Grid>
  );
};

export default RoomsAutocomplete;
