import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import {
  Button,
  Box,
  Typography,
  Collapse,
  List,
  ListItem,
  ListItemText,
  Link as MuiLink,
  CircularProgress,
  Grid
} from '@mui/material';
import { grey, blue, green } from '@mui/material/colors';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';

const DuplicatesButton = ({ subjectId }) => {
  const [duplicates, setDuplicates] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [open, setOpen] = useState(false);
  const [hasFetched, setHasFetched] = useState(false);
  const buttonRef = useRef(null);
  const [historyState, setHistoryState] = useState({});

  function getCsrfToken() {
    return document.cookie
      .split('; ')
      .find(row => row.startsWith('csrftoken='))
      ?.split('=')[1];
  }

  const fetchDuplicates = async () => {
    try {
      setLoading(true);
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/dashboard/api/container/${subjectId}/`,
        {
          method: 'GET',
          credentials: 'include',
          headers: {
            'X-CSRFToken': getCsrfToken(),
            'Content-Type': 'application/json'
          }
        }
      );
      
      if (!response.ok) throw new Error('Сетевая ошибка');
      
      const data = await response.json();
      
      if (data.count > 0) {
        setDuplicates(data);
      } else {
        setDuplicates({ count: 0, results: [] });
      }
    } catch (err) {
      console.error(err);
      setError(true);
    } finally {
      setLoading(false);
      setHasFetched(true);
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting && !hasFetched) {
          fetchDuplicates();
        }
      },
      { threshold: 0.1 }
    );

    if (buttonRef.current) observer.observe(buttonRef.current);
    
    return () => {
      if (buttonRef.current) observer.unobserve(buttonRef.current);
    };
  }, [subjectId, hasFetched]);

  const hasDuplicates = duplicates?.count > 0;

  const handleToggleHistory = (duplicateId) => {
    setHistoryState((prev) => {
      const current = prev[duplicateId] || { open: false, data: null, isEmpty: false };

      if (current.data === null) {
        axios
          .get(`${process.env.REACT_APP_API_URL}/dashboard/api/subject/${duplicateId}/history/`, 
            { withCredentials: true }
          )
          .then((res) => {
            const allowedKeywords = [
              'price',
              'drop',
              'raising',
              'deactiv',
              'promotion_stop',
              'crm',
              'duplicat',
            ];

            const filteredData = res.data.filter((item) => {
              if (!item.history_change_reason) return false;
              const reasonLower = item.history_change_reason.toLowerCase();
              return allowedKeywords.some((kw) => reasonLower.includes(kw));
            });

            setHistoryState((prevInside) => ({
              ...prevInside,
              [duplicateId]: {
                open: true,
                data: filteredData,
                isEmpty: filteredData.length === 0,
              },
            }));
          })
          .catch((err) => {
            console.error('Ошибка загрузки истории:', err);
            setHistoryState((prevInside) => ({
              ...prevInside,
              [duplicateId]: {
                ...prevInside[duplicateId],
                open: false,
                data: [],
                isEmpty: true,
              },
            }));
          });
      } else {
        return {
          ...prev,
          [duplicateId]: {
            ...current,
            open: !current.open,
          },
        };
      }
      return prev;
    });
  };

  return (
    <Box mt={2} ref={buttonRef}>
      {loading ? (
        <Box display="flex" alignItems="center">
          <CircularProgress size={24} />
          <Typography variant="body2" ml={1}>
            Загрузка дубликатов...
          </Typography>
        </Box>
      ) : (
        <Button
          variant="contained"
          onClick={() => setOpen(!open)}
          sx={{
            backgroundColor: hasDuplicates ? blue[500] : grey[500],
            '&:hover': {
              backgroundColor: hasDuplicates ? blue[700] : grey[700],
            },
          }}
          disabled={!hasDuplicates && hasFetched}
        >
          {hasDuplicates
            ? `Дубликаты (${duplicates.count})`
            : 'Дубликаты отсутствуют'}
        </Button>
      )}

      {hasDuplicates && (
        <Collapse in={open}>
          <List sx={{ mt: 1, bgcolor: '#f1f5f9', borderRadius: 2 }}>
            {duplicates.results.map((dup) => {
              const historyStateItem = historyState[dup.id] || {};
              const { open: isHistoryOpen, data: rawHistoryData, isEmpty } = historyStateItem;
              const historyData = rawHistoryData || [];

              return (
                <ListItem key={dup.id} divider>
                  <ListItemText
                    primary={
                      <MuiLink
                        href={dup.link}
                        target="_blank"
                        rel="noopener noreferrer"
                        variant="body1"
                        sx={{ textDecoration: 'none' }}
                      >
                        {dup.name} {dup.activ ? (
        <CheckCircleIcon sx={{ color: green[500], mr: 1 }} />
      ) : (
        <CancelIcon sx={{ color: grey[500], mr: 1 }} />
      )}
                      </MuiLink>
                    }
                    secondary={
                      <>
                        <Typography variant="body2">
                          Цена: {dup.price}
                        </Typography>
                        <Typography variant="body2">
                          Продавец: {dup.seller?.seller_name_class || '—'} ({dup.seller?.seller_type_class || '—'})
                        </Typography>
                        <Typography variant="body2">
                          Создан: {dup.creat_data}
                        </Typography>

                        <Button
                          variant="outlined"
                          size="small"
                          sx={{ mt: 1 }}
                          onClick={() => handleToggleHistory(dup.id)}
                          disabled={isEmpty}
                        >
                          {isHistoryOpen ? 'Скрыть историю' : 'Показать историю'}
                        </Button>

                        <Collapse in={isHistoryOpen} sx={{ mt: 1 }}>
                          {historyData.length === 0 ? (
                            <Typography variant="body2" sx={{ mt: 1 }}>
                              Нет значимых изменений
                            </Typography>
                          ) : (
                            <List sx={{ mt: 1, bgcolor: '#eef2f6', borderRadius: 1 }}>
                              {historyData.map((item, index) => (
                                <ListItem key={index} divider>
                                  <Grid container spacing={2}>
                                    <Grid item xs={4}>
                                      <Typography variant="body2">
                                        {new Date(item.history_date).toLocaleString()}
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                      <Typography variant="body2">
                                        {item.price} ₽
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                      <Typography variant="body2">
                                        {item.history_change_reason || '—'}
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                </ListItem>
                              ))}
                            </List>
                          )}
                        </Collapse>
                      </>
                    }
                    secondaryTypographyProps={{ component: 'div' }}
                  />
                </ListItem>
              );
            })}
          </List>
        </Collapse>
      )}
    </Box>
  );
};

export default DuplicatesButton;