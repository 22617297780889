// src/components/PublicationsPage/PublicationsFilter.jsx
import React from 'react';
import { Form, Button, DatePicker, Checkbox } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import moment from 'moment';

const { RangePicker } = DatePicker;

function PublicationsFilter({
  sources,
  defaultStartDate,
  defaultEndDate,
  defaultSelectedSources,
  loading,
  onFilterApply,
}) {
  const [form] = Form.useForm();

  // Создаем initialValues только если оба значения заданы, иначе пустой массив
  const initialRange =
    defaultStartDate && defaultEndDate
      ? [moment(defaultStartDate, 'YYYY-MM-DD'), moment(defaultEndDate, 'YYYY-MM-DD')]
      : [];

  const handleFinish = (values) => {
    const [startMoment, endMoment] = values.dateRange || [];
    const startStr = startMoment ? startMoment.format('YYYY-MM-DD') : '';
    const endStr = endMoment ? endMoment.format('YYYY-MM-DD') : '';
    // Массив выбранных источников
    const checkedSources = values.srcGroup || [];
    onFilterApply({
      startDate: startStr,
      endDate: endStr,
      sources: checkedSources.map(String),
    });
  };

  return (
    // Добавляем key, основанный на defaultStartDate и defaultEndDate, чтобы форма перезапускалась,
    // когда родитель меняет фильтры – это помогает избежать проблем с «скачками» в календаре.
    <Form
      key={`${defaultStartDate}-${defaultEndDate}`}
      form={form}
      layout="vertical"
      onFinish={handleFinish}
      initialValues={{
        dateRange: initialRange,
        srcGroup: defaultSelectedSources.map(Number),
      }}
      style={{ background: '#fafafa', padding: 16, borderRadius: 8 }}
    >
      <Form.Item label="Период" name="dateRange">
        <RangePicker format="YYYY-MM-DD" />
      </Form.Item>

      <Form.Item label="Источники" name="srcGroup">
        <Checkbox.Group>
          {sources.map((src) => (
            <Checkbox key={src.id} value={src.id}>
              {src.name}
            </Checkbox>
          ))}
        </Checkbox.Group>
      </Form.Item>

      <Button type="primary" htmlType="submit" icon={<SearchOutlined />} loading={loading}>
        Применить
      </Button>
    </Form>
  );
}

export default PublicationsFilter;
