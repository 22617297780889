import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import axios from 'axios';

const LazyDataProvider = forwardRef(({ subjectId, onDataLoaded, children }, ref) => {
  const [isInView, setIsInView] = useState(false);
  const [workStatusData, setWorkStatusData] = useState(null);
  const observerRef = useRef(null);

  // Метод для перезагрузки данных
  const reloadData = async () => {
    try {
      console.log('Получение данных объекта')
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/dashboard/api/subject/${subjectId}/user-status/`,
        { withCredentials: true }
      );
      setWorkStatusData(res.data);
      onDataLoaded?.(res.data);
    } catch (err) {
      console.error('Ошибка при получении статуса объекта:', err);
    }
  };

  useImperativeHandle(ref, () => ({
    reloadData,
  }));

  // 1. Отслеживаем видимость
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const [entry] = entries;
        if (entry.isIntersecting && !isInView) {
          setIsInView(true);
        }
      },
      { threshold: 0.1 }
    );

    if (observerRef.current) observer.observe(observerRef.current);
    return () => observer.disconnect();
  }, [isInView]);

  // 2. Загружаем данные при появлении в зоне видимости
  useEffect(() => {
    if (isInView && !workStatusData) {
      reloadData();
    }
  }, [isInView, workStatusData, subjectId]);

  return <div ref={observerRef}>{children}</div>;
});

export default LazyDataProvider;
