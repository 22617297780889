// src/components/StartImgAPIProcess.js
import React, { useState, useEffect } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { Button, Select, message, Form } from "antd";

const { Option } = Select;

const StartImgAPIProcess = ({ user }) => {
  const [configFiles, setConfigFiles] = useState([]);
  const [selectedConfigFile, setSelectedConfigFile] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [headless, setHeadless] = useState(true);

  // Загружаем список конфигурационных файлов
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/config-files/`, {
        withCredentials: true,
      })
      .then((response) => {
        setConfigFiles(response.data);
      })
      .catch((error) => {
        console.error("Ошибка загрузки конфигурационных файлов:", error);
        message.error("Не удалось загрузить конфигурационные файлы.");
      });
  }, []);

  const handleStartProcess = () => {
    if (!user) {
      message.warning("Сначала войдите в систему (пользователь не аутентифицирован).");
      return;
    }

    if (!selectedConfigFile) {
      message.warning("Выберите конфигурационный файл для запуска процесса.");
      return;
    }

    setIsLoading(true);

    axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/img-api/start/`,
        {
          config_file: selectedConfigFile,
          headless: headless,
        },
        {
          headers: {
            "X-CSRFToken": Cookies.get("csrftoken") || "",
          },
          withCredentials: true,
        }
      )
      .then((response) => {
        message.success(response.data.message);
      })
      .catch((error) => {
        if (error.response) {
          const { status, data } = error.response;
          console.error("Ошибка при запуске процесса:", status, data);
          if (data && data.error) {
            message.error(`Ошибка: ${data.error}`);
          } else {
            message.error("Ошибка при запуске процесса. Код " + status);
          }
        } else {
          message.error("Ошибка при запуске процесса (нет ответа).");
          console.error(error);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div style={{ marginBottom: "20px", padding: "0 20px" }}>
      <Form layout="inline">
        <Form.Item>
          <Select
            placeholder="Выберите конфигурационный файл"
            value={selectedConfigFile}
            onChange={setSelectedConfigFile}
            style={{ width: "300px" }}
          >
            {configFiles.map((file) => (
              <Option key={file.filename} value={file.filename}>
                {file.city}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item>
          <Button type="primary" onClick={handleStartProcess} loading={isLoading}>
            Запуск
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default StartImgAPIProcess;
