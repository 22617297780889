import React, { useEffect, useState, useCallback } from 'react';
import { Button, CircularProgress, Typography, Box } from '@mui/material';
import axios from 'axios';

import { WindowScroller, AutoSizer, List } from 'react-virtualized';

import SubjectFilterLite from '../FILTERS/SubjectFilterLite';
import ObjectDetailsLite from './ObjectDetailsLite';
import ObjectDetailsNoCrm from '../OBJECT_no_CRM/ObjectDetailsNoCrm';
import SubjectSort from './SubjectSortLite';
import YandexMapForObjects from '../MAPS/YandexMapForObjects';

// === Утилита поверхностного сравнения двух объектов (фильтров):
function shallowEqual(objA, objB) {
  if (objA === objB) return true;
  const keysA = Object.keys(objA);
  const keysB = Object.keys(objB);
  if (keysA.length !== keysB.length) return false;
  for (let key of keysA) {
    if (objA[key] !== objB[key]) return false;
  }
  return true;
}

// Генератор ключа для локального кэша списка объектов.
function buildCacheKeySubjects({ filters, sortOrder, viewMode, page, isCRM }) {
  return JSON.stringify({ filters, sortOrder, viewMode, page, isCRM });
}

// Генератор ключа для счётчика (count). Здесь page не нужен.
function buildCacheKeyCount({ filters, sortOrder, viewMode, isCRM }) {
  return `count_${JSON.stringify({ filters, sortOrder, viewMode, isCRM })}`;
}

// =========================
// Главный компонент
// =========================
export default function SubjectListLite({ user }) {
  // Определяем, является ли пользователь CRM-пользователем или нет
  const isCRM =
    user &&
    (
      (user.crm_user && user.crm_access === 'open') ||
      user.role === 'admin'
    );
  
    console.log('Значение', isCRM);


  // Базовые пути к API
  const baseURL = process.env.REACT_APP_API_URL;
  const SUBJECTS_API = isCRM
    ? `${baseURL}/dashboard/api/subject-crm/`
    : `${baseURL}/dashboard/api/subject-public/`;
  const SUBJECTS_COUNT_API = isCRM
    ? `${baseURL}/dashboard/api/subject-crm/count/`
    : `${baseURL}/dashboard/api/subject-public/count/`;

  // ====== Состояния ======
  const [subjects, setSubjects] = useState([]);
  const [filters, setFilters] = useState({
    city: null, // Пока город не выбран, не грузим
    active_status: 'all',
    seller_type: 'Все',
    districts: 'all',
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  // Флаг загрузки (общий)
  const [isLoading, setIsLoading] = useState(false);

  // Счётчик общего количества
  const [totalCount, setTotalCount] = useState(null);
  const [countLoading, setCountLoading] = useState(false);

  // Сортировка (читаем из localStorage один раз при маунте)
  const [sortOrder, setSortOrder] = useState(
    () => localStorage.getItem('sortOrder') || ''
  );

  // Режим отображения: 'list' или 'map'
  const [viewMode, setViewMode] = useState('list');

  // Локальные кэши
  const [localCacheSubjects, setLocalCacheSubjects] = useState({});
  const [localCacheCount, setLocalCacheCount] = useState({});

  // ===========================
  //        Функции-загрузчики
  // ===========================
  const fetchSubjects = useCallback(
    async (pageNum, filtersObj, isReset = false) => {
      setIsLoading(true);
      try {
        const cacheKey = buildCacheKeySubjects({
          filters: filtersObj,
          sortOrder,
          viewMode,
          page: pageNum,
          isCRM,
        });
        const cachedSubjects = localCacheSubjects[cacheKey];

        if (cachedSubjects) {
          // console.log('Используем локальный кэш для subjects:', cacheKey);
          if (isReset) {
            setSubjects(cachedSubjects);
          } else {
            setSubjects((prev) => [...prev, ...cachedSubjects]);
          }
          setIsLoading(false);
          return;
        }

        // Если кэша нет — делаем запрос
        const params = { ...filtersObj, sort: sortOrder };
        if (viewMode === 'map') {
          params.map = 'true'; // При режиме карты получаем все объекты
        } else {
          params.page = pageNum;
        }

        const response = await axios.get(SUBJECTS_API, {
          params,
          withCredentials: true,
        });
        const data = response.data;
        const items = data.results || data; // Если вернулся пагинированный формат (results)

        if (isReset) {
          setSubjects(items);
        } else {
          setSubjects((prev) => [...prev, ...items]);
        }

        if (viewMode === 'map') {
          setHasMore(false); // При map грузим всё сразу
        } else {
          setHasMore(data.next !== null); // При list проверяем наличие next
        }

        // Сохраняем в локальный кэш
        setLocalCacheSubjects((prev) => ({
          ...prev,
          [cacheKey]: items,
        }));
      } catch (error) {
        console.error('Ошибка при загрузке объектов:', error);
      } finally {
        setIsLoading(false);
      }
    },
    [viewMode, sortOrder, localCacheSubjects]
  );

  const fetchTotalCount = useCallback(
    async (filtersObj) => {
      setCountLoading(true);
      try {
        const cacheKey = buildCacheKeyCount({
          filters: filtersObj,
          sortOrder,
          viewMode,
          isCRM,
        });
        const cachedCount = localCacheCount[cacheKey];

        if (cachedCount !== undefined) {
          // console.log('Используем локальный кэш для count:', cacheKey);
          setTotalCount(cachedCount);
          setCountLoading(false);
          return;
        }

        const resp = await axios.get(SUBJECTS_COUNT_API, {
          params: filtersObj,
          withCredentials: true,
        });
        const newCount = resp.data.count;
        setTotalCount(newCount);

        setLocalCacheCount((prev) => ({
          ...prev,
          [cacheKey]: newCount,
        }));
      } catch (error) {
        console.error('Ошибка при загрузке count:', error);
      } finally {
        setCountLoading(false);
      }
    },
    [viewMode, sortOrder, localCacheCount]
  );

  // ===========================
  //          useEffects
  // ===========================
  useEffect(() => {
    if (!filters.city) {
      return;
    }
    setSubjects([]);
    setCurrentPage(1);
    fetchSubjects(1, filters, true);
    fetchTotalCount(filters);
  }, [filters, user, sortOrder, viewMode, isCRM, fetchSubjects, fetchTotalCount]);

  useEffect(() => {
    if (!filters.city) return;
    if (currentPage > 1 && viewMode === 'list') {
      fetchSubjects(currentPage, filters, false);
    }
  }, [currentPage, viewMode, filters, fetchSubjects]);

  // ===========================
  //         Обработчики
  // ===========================
  const handleFilterChange = (newFilters) => {
    if (!shallowEqual(filters, newFilters)) {
      setFilters(newFilters);
    }
  };

  const handleSort = (order) => {
    setSortOrder(order);
    setCurrentPage(1);
    localStorage.setItem('sortOrder', order);
  };

  const handleLoadMore = () => {
    setCurrentPage((prev) => prev + 1);
  };

  const toggleViewMode = () => {
    setViewMode((prev) => (prev === 'list' ? 'map' : 'list'));
  };

  // ===========================
  //   Рендер списка (виртуализ)
  // ===========================
  // rowRenderer — функция для List из react-virtualized
  const rowRenderer = ({ index, key, style }) => {
    const subject = subjects[index];
    if (!subject) return null;

    return (
      <div key={key} style={style}>
        {isCRM ? (
          <ObjectDetailsLite subject={subject} isCRM={isCRM} />
        ) : (
          <ObjectDetailsNoCrm subject={subject} />
        )}
      </div>
    );
  };

  return (
    <div style={{ padding: '14px' }}>
      <Typography variant="h4" gutterBottom className="text-2xl font-bold">
        {isCRM ? 'СПИСОК ОБЪЕКТОВ (full version)' : 'Список объектов'}{' '}
        {countLoading ? (
          <CircularProgress size={20} />
        ) : totalCount !== null ? (
          `(${totalCount})`
        ) : (
          ''
        )}
      </Typography>

      <Box mb={2}>
        <Button variant="contained" onClick={toggleViewMode}>
          {viewMode === 'list' ? 'Перейти к карте' : 'Перейти к списку'}
        </Button>
      </Box>

      {/* Фильтры */}
      <SubjectFilterLite onFilterChange={handleFilterChange} user={user} isCRM={isCRM} />

      {/* Если список — показываем react-virtualized (WindowScroller + AutoSizer + List) */}
      {viewMode === 'list' && (
        <>
          <SubjectSort onSort={handleSort} isCRM={isCRM} />

          <WindowScroller>
            {({ height, isScrolling, onChildScroll, scrollTop }) => (
              <AutoSizer disableHeight>
                {({ width }) => (
                  <List
                    autoHeight
                    height={height}
                    width={width}
                    isScrolling={isScrolling}
                    onScroll={onChildScroll}
                    scrollTop={scrollTop}
                    rowCount={subjects.length}
                    rowHeight={isCRM ? 950 : 365}       // фиксированная высота строки (подберите под дизайн)
                    rowRenderer={rowRenderer}
                    overscanRowCount={2}  // буфер вне видимой области
                    // style={{}} // можно добавить стили, если нужно
                  />
                )}
              </AutoSizer>
            )}
          </WindowScroller>

          {/* Кнопка "Загрузить ещё" (пагинация) */}
          {hasMore && !isLoading && subjects.length > 0 && (
            <Box mt={2}>
              <Button variant="contained" color="primary" onClick={handleLoadMore}>
                Загрузить ещё
              </Button>
            </Box>
          )}

          {/* Спиннер загрузки */}
          {isLoading && (
            <Box mt={2}>
              <CircularProgress />
            </Box>
          )}
        </>
      )}

      {/* Если карта — показываем карту */}
      {viewMode === 'map' && (
        <YandexMapForObjects
          key={filters.city}
          subjects={subjects}
          isCRM={isCRM}
          selectedCity={filters.city}
          isLoading={isLoading}
        />
      )}
    </div>
  );
}
