import React from 'react';
import { Link } from 'react-router-dom';
import headerImage from '../assets/header-image.png'; // пример импорта картинки

export default function PromoHeader() {
  return (
    <div className="w-full bg-blue-50 py-10">
      <div className="container mx-auto flex flex-col md:flex-row items-center px-4">
        {/* Левая часть: заголовок/текст */}
        <div className="md:w-1/2 w-full mb-6 md:mb-0">
          <h1 className="text-3xl md:text-5xl font-bold text-blue-900 mb-4">
            Современные инструменты для анализа рынка недвижимости
          </h1>
          <p className="text-gray-700 mb-6">
            Узнайте историю объекта, отследите динамику цен и получите конкурентное преимущество
            на рынке вторичного жилья. Наша платформа поможет принимать взвешенные решения
            и повышать доверие клиентов.
          </p>
          <div className="space-x-2">
            <Link
              to="/lite_subjects"
              className="bg-blue-600 hover:bg-blue-700 text-white px-4 py-2 rounded"
            >
              Посмотреть объекты
            </Link>
            <button
              className="bg-orange-500 hover:bg-orange-600 text-white px-4 py-2 rounded"
              onClick={() => alert('Бета-доступ скоро доступен!')}
            >
              Попробовать бесплатно
            </button>
          </div>
        </div>

        {/* Правая часть: картинка */}
        <div className="md:w-1/2 w-full flex justify-center">
          {/* Замените на нужную вам картинку или SVG */}
          <img
            src={headerImage}
            alt="Аналитика недвижимости"
            className="max-w-full h-auto"
          />
        </div>
      </div>
    </div>
  );
}
