// hooks/useProfiles.js
import { useState, useEffect } from "react";
import axios from "axios";
import Cookies from "js-cookie";

const getCsrfToken = () => {
  const csrfToken = Cookies.get("csrftoken");
  return csrfToken || "";
};

export function useProfiles(user) {
  const [profiles, setProfiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchProfiles = async () => {
    if (!user) {
      setProfiles([]);
      return;
    }
    setLoading(true);
    try {
      const resp = await axios.get(`${process.env.REACT_APP_API_URL}/api/profiles/`, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": getCsrfToken(),
        },
      });
      // Используем resp.data напрямую, так как API возвращает массив
      setProfiles(resp.data || []);
    } catch (err) {
      setError(err);
      setProfiles([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProfiles();
  }, [user]);

  return { profiles, setProfiles, fetchProfiles, loading, error };
}
