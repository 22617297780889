// components/parsingStatusLabels.js
export const parsingStatusLabels = {
    "running": "Выполняется",
    "completed": "Выполнен",
    "failed": "Ошибка",
    "stopped": "Остановлен",
    "checking": "Проверяю",
    "lessData": "Недостаточно данных",
    "resume": "Возобновлён",
  };
  
  export const parsingStatusColors = {
    "running": "processing",
    "completed": "green",
    "failed": "red",
    "stopped": "orange",
    "checking": "blue",
    "lessData": "warning",
    "resume": "purple",
  };
  