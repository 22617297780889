import React from 'react';
import { Box, Typography, Link } from '@mui/material';
import WorkStatus from './WorkStatus';

const CardHeader = ({ subject, workStatusData, onClientUpdated, forceRender }) => {
  const backgroundColor = subject.activ ? '#1565c0' : '#cbd5e1';

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          backgroundColor,
          padding: '8px',
          borderRadius: '4px',
        }}
      >
        <Box>
          <Link href={subject.link} sx={{ color: '#fff', textDecoration: 'none' }} target="_blank">
            <Typography variant="h6">
              {subject.name || `Объект #${subject.id}`}
            </Typography>
          </Link>
        </Box>

        <Box>
          <Typography variant="body2" sx={{ color: '#fff' }}>
            Дата создания в базе: {subject.creat_data || 'Нет даты'}
          </Typography>
        </Box>

        {/* Ставим key на WorkStatus, привязывая к forceRender */}
        <Box key={`workStatus-${forceRender}`}>
          <WorkStatus
            subjectId={subject.id}
            workStatusData={workStatusData}
            onClientUpdated={onClientUpdated}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default CardHeader;
