import React from 'react';
import {
  TextField,
  Box,
  Grid,
  ToggleButton,
  ToggleButtonGroup,
  IconButton, 
  InputAdornment
} from '@mui/material';
import { AiOutlineCheckCircle, AiOutlineCloseCircle } from 'react-icons/ai';
import { FaUser, FaBuilding } from 'react-icons/fa';
import RoomsAutocomplete from './RoomsAutocomplete';
import ClearIcon from '@mui/icons-material/Clear';

const CommonFilterForm = ({
  searchTerm,
  setSearchTerm,
  selectedRooms,
  setSelectedRooms,
  activeStatuses,
  setActiveStatuses,
  sellerTypes,
  setSellerTypes,
  tempPriceFrom,
  setTempPriceFrom,
  tempPriceTo,
  setTempPriceTo,
  tempFloorFrom,
  setTempFloorFrom,
  tempFloorTo,
  setTempFloorTo,
  tempYearBuiltFrom,
  setTempYearBuiltFrom,
  tempYearBuiltTo,
  setTempYearBuiltTo,
  isCRM,
}) => {
  const roomsOptions = ['Студия', '1', '2', '3', '4', '5'];

  const handleToggleActive = (event, newActive) => {
    setActiveStatuses(newActive);
  };

  const handleToggleSeller = (event, newSeller) => {
    setSellerTypes(newSeller);
  };

  const handleSearchTermChange = (e) => {
    let value = e.target.value;
    const contextIndex = value.indexOf('?context');
    if (contextIndex !== -1) {
      value = value.substring(0, contextIndex);
    }
    setSearchTerm(value);
  };

  const handleRoomsChange = (newSelectedRooms) => {
    setSelectedRooms(newSelectedRooms);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        gap: 3,
        mt: 3,
        mb: 1,
        width: '100%',
      }}
    >
      <Box>
        {/* Активность объявления */}
        <ToggleButtonGroup
          value={activeStatuses}
          onChange={handleToggleActive}
          aria-label="active status"
          color="primary"
          size="small"
        >
          <ToggleButton value="Активно" aria-label="Активно" sx={{ whiteSpace: 'nowrap' }}>
            <AiOutlineCheckCircle style={{ marginRight: 4 }} />
            Объявление активно
          </ToggleButton>
          <ToggleButton value="Неактивно" aria-label="Неактивно" sx={{ whiteSpace: 'nowrap' }}>
            <AiOutlineCloseCircle style={{ marginRight: 4 }} />
            Объявление неактивно
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>

      <Box>
        {/* Тип продавца */}
        <ToggleButtonGroup
          value={sellerTypes}
          onChange={handleToggleSeller}
          aria-label="seller types"
          color="secondary"
          size="small"
        >
          <ToggleButton value="Частное лицо" aria-label="private" sx={{ whiteSpace: 'nowrap' }}>
            <FaUser style={{ marginRight: 4 }} />
            Продавец: Частное лицо
          </ToggleButton>
          <ToggleButton value="Агентство" aria-label="agency" sx={{ whiteSpace: 'nowrap' }}>
            <FaBuilding style={{ marginRight: 4 }} />
            Агентство
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>

      {/* Если isCRM === true, отображаем блок с ценой */}
      {isCRM && (
        <Box>
          <Box display="flex" alignItems="center">
            <TextField
              size="small"
              label="Цена от"
              value={tempPriceFrom}
              onChange={(e) => setTempPriceFrom(e.target.value)}
              type="number"
              InputProps={{ inputProps: { min: 0 } }}
              sx={{ mr: 1 }}
            />
            <TextField
              size="small"
              label="Цена до"
              value={tempPriceTo}
              onChange={(e) => setTempPriceTo(e.target.value)}
              type="number"
              InputProps={{ inputProps: { min: 0 } }}
            />
          </Box>
        </Box>
      )}

        <Box>
          <Box display="flex" alignItems="center">
            <TextField
              size="small"
              label="Этаж от"
              value={tempFloorFrom}
              onChange={(e) => setTempFloorFrom(e.target.value)}
              type="number"
              InputProps={{ inputProps: { min: 0 } }}
              sx={{ mr: 1 }}
            />
            <TextField
              size="small"
              label="Этаж до"
              value={tempFloorTo}
              onChange={(e) => setTempFloorTo(e.target.value)}
              type="number"
              InputProps={{ inputProps: { min: 0 } }}
            />
          </Box>
        </Box>
        <Box>
          <Box display="flex" alignItems="center">
            <TextField
              size="small"
              label="Год постройки от"
              value={tempYearBuiltFrom}
              onChange={(e) => setTempYearBuiltFrom(e.target.value)}
              type="number"
              InputProps={{ inputProps: { min: 1930, max: 2025 } }}
              sx={{ width: 180, mr: 1 }}
            />
            <TextField
              size="small"
              label="Год постройки до"
              value={tempYearBuiltTo}
              onChange={(e) => setTempYearBuiltTo(e.target.value)}
              type="number"
              InputProps={{ inputProps: { min: 1930, max: 2025 } }}
              sx={{ width: 180, mr: 1 }}
            />
          </Box>
        </Box>

      {/* Фильтр по количеству комнат */}
      <RoomsAutocomplete
        roomsOptions={roomsOptions}
        selectedRooms={selectedRooms}
        handleRoomChange={handleRoomsChange}
      />

      {/* Отдельный контейнер для поиска */}
      <Grid item xs={12} sm={4}>
        <Box display="flex" flexDirection="column">
          <TextField
            fullWidth
            label="Поиск по ссылке или адресу"
            value={searchTerm}
            onChange={handleSearchTermChange}
            placeholder="Вставьте ссылку avito или начните вводить улицу"
            size="small"
            variant="outlined"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {searchTerm && (
                    <IconButton onClick={() => setSearchTerm('')} edge="end">
                      <ClearIcon />
                    </IconButton>
                  )}
                </InputAdornment>
              ),
            }}
          />
        </Box>
      </Grid>
    </Box>
  );
};

export default CommonFilterForm;
