import React, { useState, useEffect, useMemo } from 'react';
import { Box, Button, Grid, CircularProgress } from '@mui/material';

// Импортируем разбитые компоненты
import CityDistrictFilter from './CityDistrictFilter';
import CommonFilterForm from './CommonFilterForm';
import CRMFilterForm from './CRMFilterForm';

const SubjectFilterLite = ({ onFilterChange, user, isCRM }) => {
  // ======= Состояния, отвечающие за загрузку городов =======
  const [citiesLoaded, setCitiesLoaded] = useState(false);
  const [cityList, setCityList] = useState([]);

  // Город по умолчанию выбираем после загрузки
  const [selectedCity, setSelectedCity] = useState(null);

  // ======= Районы =======
  const [districts, setDistricts] = useState([]);
  const [selectedDistricts, setSelectedDistricts] = useState([]);

  // ======= Прочие фильтры =======
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedRooms, setSelectedRooms] = useState([]);
  const [activeStatuses, setActiveStatuses] = useState(['Активно']);
  const [sellerTypes, setSellerTypes] = useState(['Частное лицо']);
  const [tempPriceFrom, setTempPriceFrom] = useState('');
  const [tempPriceTo, setTempPriceTo] = useState('');
  const [priceFrom, setPriceFrom] = useState('');
  const [priceTo, setPriceTo] = useState('');
  const [tempFloorFrom, setTempFloorFrom] = useState('');
  const [tempFloorTo, setTempFloorTo] = useState('');
  const [floorFrom, setFloorFrom] = useState('');
  const [floorTo, setFloorTo] = useState('');
  const [tempYearBuiltFrom, setTempYearBuiltFrom] = useState('');
  const [tempYearBuiltTo, setTempYearBuiltTo] = useState('');
  const [yearBuiltFrom, setYearBuiltFrom] = useState('');
  const [yearBuiltTo, setYearBuiltTo] = useState('');

  // ======= CRM-фильтры =======
  const [activityStatuses, setActivityStatuses] = useState([]);
  const [workStatuses, setWorkStatuses] = useState([]);
  const [selectedClientStatus, setSelectedClientStatus] = useState('Все');
  const [selectedDaysWithoutActivity, setSelectedDaysWithoutActivity] = useState('');
  const [contactDueOptions, setContactDueOptions] = useState('');
  const [showHidden, setShowHidden] = useState(false);

  // Видимость фильтров
  const [areFiltersVisible, setAreFiltersVisible] = useState(true);

  // ======= Загружаем города =======
  useEffect(() => {
    const fetchCities = async () => {
      try {
        const endpoint = isCRM
          ? `${process.env.REACT_APP_API_URL}/dashboard/api/cities/`
          : `${process.env.REACT_APP_API_URL}/dashboard/api/public/cities/`;

        const resp = await fetch(endpoint, {
          method: 'GET',
          credentials: 'include',
          headers: { 'Content-Type': 'application/json' },
        });
        const data = await resp.json();
        const fetchedCities = data.results || [];

        setCityList(fetchedCities);

        // Определяем дефолтный город
        if (fetchedCities.length > 0) {
          // Ищем Иркутск
          const irkutskCity = fetchedCities.find(
            (city) => city.key.toLowerCase() === 'irkutsk'
          );
          if (irkutskCity) {
            setSelectedCity(irkutskCity.key);
          } else {
            // Если Иркутска нет — берем первый город из списка
            setSelectedCity(fetchedCities[0].key);
          }
        }

        // Ставим флаг, что города загружены
        setCitiesLoaded(true);
      } catch (err) {
        console.error('Ошибка при загрузке городов:', err);
      }
    };

    fetchCities();
  }, [isCRM]);

  // ======= Фильтруем доступные пользователю города (если не админ) =======
  const cityOptions = useMemo(() => {
    if (!user) return cityList;
    if (user.role === 'admin') {
      return cityList;
    }
    return cityList.filter((city) => user.allowed_cities.includes(city.id));
  }, [user, cityList]);

  // ======= Загрузка районов при смене города =======
  useEffect(() => {
    if (!selectedCity) {
      setDistricts([]);
      setSelectedDistricts([]);
      return;
    }
    const fetchDistricts = async (cityKey) => {
      try {
        const resp = await fetch(
          `${process.env.REACT_APP_API_URL}/dashboard/api/districts/?city=${cityKey}`,
          { withCredentials: true }
        );
        const data = await resp.json();
        setDistricts(data.districts || []);
      } catch (err) {
        console.error('Ошибка при загрузке районов:', err);
      }
    };

    fetchDistricts(selectedCity);
  }, [selectedCity]);

  // ======= Debounce для цены =======
  useEffect(() => {
    const handler = setTimeout(() => {
      setPriceFrom(tempPriceFrom);
      setPriceTo(tempPriceTo);
    }, 1500);
    return () => clearTimeout(handler);
  }, [tempPriceFrom, tempPriceTo]);

  // ======= Debounce для этажей =======
  useEffect(() => {
    const handler = setTimeout(() => {
      setFloorFrom(tempFloorFrom);
      setFloorTo(tempFloorTo);
    }, 1500);
    return () => clearTimeout(handler);
  }, [tempFloorFrom, tempFloorTo]);

  // ======= Debounce для года постройки =======
  useEffect(() => {
    const handler = setTimeout(() => {
      // Можно добавить проверку, чтобы yearBuiltFrom <= yearBuiltTo
      setYearBuiltFrom(tempYearBuiltFrom);
      setYearBuiltTo(tempYearBuiltTo);
    }, 1500);
    return () => clearTimeout(handler);
  }, [tempYearBuiltFrom, tempYearBuiltTo]);

  // ======= Собираем все фильтры и отправляем в родителя =======
  useEffect(() => {
    // Если ещё не загрузили список городов или не выбрали city — не делаем onFilterChange
    if (!citiesLoaded || !selectedCity) {
      return;
    }

    let finalActiveStatus = 'all';
    if (activeStatuses.length === 1 && activeStatuses[0] === 'Активно') {
      finalActiveStatus = 'Активно';
    } else if (activeStatuses.length === 1 && activeStatuses[0] === 'Неактивно') {
      finalActiveStatus = 'Неактивно';
    }

    let finalSellerType = 'Все';
    if (sellerTypes.length === 1 && sellerTypes[0] === 'Частное лицо') {
      finalSellerType = 'Частное лицо';
    } else if (sellerTypes.length === 1 && sellerTypes[0] === 'Агентство') {
      finalSellerType = 'Агентство';
    }

    let finalActivityStatus = 'Все';
    if (activityStatuses.length === 1 && activityStatuses[0] === 'С активностями') {
      finalActivityStatus = 'С активностями';
    } else if (activityStatuses.length === 1 && activityStatuses[0] === 'Без активностей') {
      finalActivityStatus = 'Без активностей';
    }

    let finalWorkStatus = 'Все';
    if (workStatuses.length === 1 && workStatuses[0] === 'В работе') {
      finalWorkStatus = 'В работе';
    } else if (workStatuses.length === 1 && workStatuses[0] === 'Не в работе') {
      finalWorkStatus = 'Не в работе';
    }

    let finalContactDue = contactDueOptions;

    // Формируем финальный объект
    const newFilterObj = {
      city: selectedCity,
      rooms: selectedRooms.join(','),
      districts: selectedDistricts.length > 0 ? selectedDistricts.join(',') : 'all',
      search: searchTerm,
      active_status: finalActiveStatus,
      seller_type: finalSellerType,
      price_from: priceFrom,
      price_to: priceTo,
      floor_from: floorFrom,
      floor_to: floorTo,
      year_built_from: yearBuiltFrom,
      year_built_to: yearBuiltTo,
      source: 'avito',        // для примера
      type: 'secondary',      // для примера

      // --- CRM ---
      activity_status: finalActivityStatus,
      work_status: finalWorkStatus,
      client_status: selectedClientStatus,
      days_without_activity: selectedDaysWithoutActivity,
      contact_filter: finalContactDue,
      hide: showHidden ? 'true' : 'false',
    };

    // Передаём фильтры вверх
    onFilterChange(newFilterObj);
  }, [
    citiesLoaded,
    selectedCity,
    selectedRooms,
    selectedDistricts,
    searchTerm,
    activeStatuses,
    sellerTypes,
    priceFrom,
    priceTo,
    floorFrom,
    floorTo,
    yearBuiltFrom,
    yearBuiltTo,
    activityStatuses,
    workStatuses,
    selectedClientStatus,
    selectedDaysWithoutActivity,
    contactDueOptions,
    showHidden,
    onFilterChange,
  ]);

  // ======= Сброс =======
  const handleReset = () => {
    // Если хотим по кнопке сбрасывать всё, включая город — на ваше усмотрение.
    // Часто город не трогают, сбрасывают остальные поля.
    setSelectedRooms([]);
    setSelectedDistricts([]);
    setSearchTerm('');
    setActiveStatuses(['Активно']);
    setSellerTypes(['Частное лицо']);
    setTempPriceFrom('');
    setTempPriceTo('');
    setPriceFrom('');
    setPriceTo('');
    setTempFloorFrom('');
    setTempFloorTo('');
    setFloorFrom('');
    setFloorTo('');
    setTempYearBuiltFrom('');
    setTempYearBuiltTo('');
    setYearBuiltFrom('');
    setYearBuiltTo('');
    setActivityStatuses([]);
    setWorkStatuses([]);
    setSelectedClientStatus('Все');
    setSelectedDaysWithoutActivity('');
    setContactDueOptions('');
    setShowHidden(false);
  };

  const toggleFiltersVisibility = () => {
    setAreFiltersVisible(!areFiltersVisible);
  };

  // Пока города не загружены — показываем лоадер
  if (!citiesLoaded) {
    return (
      <Box sx={{ p: 2 }}>
        <CircularProgress />
        <span style={{ marginLeft: 8 }}>Загрузка городов...</span>
      </Box>
    );
  }

  return (
    <div className="p-4 bg-white shadow-md rounded-lg mb-4 border border-gray-200">
      <Button
        variant="contained"
        color="primary"
        fullWidth
        onClick={toggleFiltersVisibility}
        style={{ marginBottom: '16px' }}
      >
        {areFiltersVisible ? 'Скрыть фильтры' : 'Показать фильтры'}
      </Button>

      {areFiltersVisible && (
        <Box>
          <Grid container spacing={2}>
            {/* Выбор города и района */}
            <CityDistrictFilter
              selectedCity={selectedCity || ''}
              setSelectedCity={setSelectedCity}
              cityOptions={cityOptions}
              selectedDistricts={selectedDistricts}
              setSelectedDistricts={setSelectedDistricts}
              districts={districts}
              handleReset={handleReset}
            />
          </Grid>

          <Grid container spacing={2}>
            {/* Общие фильтры */}
            <Grid item xs={12}>
              <CommonFilterForm
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
                selectedRooms={selectedRooms}
                setSelectedRooms={setSelectedRooms}
                activeStatuses={activeStatuses}
                setActiveStatuses={setActiveStatuses}
                sellerTypes={sellerTypes}
                setSellerTypes={setSellerTypes}
                tempPriceFrom={tempPriceFrom}
                setTempPriceFrom={setTempPriceFrom}
                tempPriceTo={tempPriceTo}
                setTempPriceTo={setTempPriceTo}
                tempFloorFrom={tempFloorFrom}
                setTempFloorFrom={setTempFloorFrom}
                tempFloorTo={tempFloorTo}
                setTempFloorTo={setTempFloorTo}
                tempYearBuiltFrom={tempYearBuiltFrom}
                setTempYearBuiltFrom={setTempYearBuiltFrom}
                tempYearBuiltTo={tempYearBuiltTo}
                setTempYearBuiltTo={setTempYearBuiltTo}
                isCRM={isCRM}
              />
            </Grid>

            {/* CRM-фильтры (если нужно) */}
            {isCRM && (
              <Grid item xs={12}>
                <CRMFilterForm
                  activityStatuses={activityStatuses}
                  setActivityStatuses={setActivityStatuses}
                  workStatuses={workStatuses}
                  setWorkStatuses={setWorkStatuses}
                  selectedClientStatus={selectedClientStatus}
                  setSelectedClientStatus={setSelectedClientStatus}
                  selectedDaysWithoutActivity={selectedDaysWithoutActivity}
                  setSelectedDaysWithoutActivity={setSelectedDaysWithoutActivity}
                  contactDueOptions={contactDueOptions}
                  setContactDueOptions={setContactDueOptions}
                  showHidden={showHidden}
                  setShowHidden={setShowHidden}
                />
              </Grid>
            )}
          </Grid>
        </Box>
      )}
    </div>
  );
};

export default SubjectFilterLite;
