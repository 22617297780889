import React, { useState, useEffect } from 'react';
import axios from 'axios';

function getCsrfToken() {
  const cookieValue = document.cookie
    .split('; ')
    .find((row) => row.startsWith('csrftoken='))
    ?.split('=')[1];
  return cookieValue;
}

const AddressNormalizer = ({ user }) => {
  const [cities, setCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState('');
  const [taskId, setTaskId] = useState(null);
  const [progress, setProgress] = useState(0);
  const [taskState, setTaskState] = useState('');
  const [error, setError] = useState(null);

  // 1) Подгружаем города ТОЛЬКО если user не null
  useEffect(() => {
    if (!user) return; // если пользователь не залогинен, выходим
    const fetchCities = async () => {
      try {
        const resp = await axios.get(
          `${process.env.REACT_APP_API_URL}/dashboard/api/cities/`,
          {
            withCredentials: true,
            headers: {
              'X-CSRFToken': getCsrfToken(),
              'Content-Type': 'application/json',
            },
          }
        );
        setCities(resp.data.results || []);
      } catch (err) {
        console.error('Ошибка при загрузке городов:', err);
        setError('Не удалось загрузить список городов. Возможно, вы не авторизованы.');
      }
    };
    fetchCities();
  }, [user]);

  // 2) Следим за статусом задачи (периодически опрашиваем бэкенд)
  useEffect(() => {
    let interval = null;
    if (taskId) {
      interval = setInterval(async () => {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/dashboard/api/task_status/${taskId}/`,
            { withCredentials: true }
          );
          const data = response.data;
          setTaskState(data.state);
          setProgress(data.progress);

          // Если задача завершена (SUCCESS/FAILURE) — перестаём опрашивать
          if (data.state === 'SUCCESS' || data.state === 'FAILURE') {
            clearInterval(interval);
          }
        } catch (error) {
          console.error('Ошибка при проверке статуса задачи:', error);
          setError('Не удалось обновить статус задачи.');
          clearInterval(interval);
        }
      }, 5000);
    }

    return () => clearInterval(interval);
  }, [taskId]);

  // 3) Запускаем нормализацию (POST)
  const handleStartNormalization = async () => {
    if (!selectedCity) {
      alert('Пожалуйста, выберите город.');
      return;
    }

    try {
      const resp = await axios.post(
        `${process.env.REACT_APP_API_URL}/dashboard/api/start_adress_norm/`,
        { city: selectedCity },
        {
          withCredentials: true,
          headers: {
            'X-CSRFToken': getCsrfToken(),
            'Content-Type': 'application/json',
          },
        }
      );
      setTaskId(resp.data.task_id);
      setTaskState('STARTED');
      setProgress(0);
      setError(null);
    } catch (error) {
      console.error('Error starting task:', error);
      setError('Не удалось запустить задачу.');
    }
  };

  // Если пользователь не залогинен, показываем сообщение (опционально)
  if (!user) {
    return <div>Пожалуйста, войдите, чтобы использовать нормализатор адресов.</div>;
  }

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Нормализатор адресов</h1>

      <div className="mb-4">
        <label htmlFor="city" className="block text-gray-700">
          Выберите город:
        </label>
        <select
          id="city"
          value={selectedCity}
          onChange={(e) => setSelectedCity(e.target.value)}
          className="mt-1 block w-full p-2 border border-gray-300 rounded"
        >
          <option value="">-- Выберите город --</option>
          {cities.map((city) => (
            <option key={city.id} value={city.key}>
              {city.name}
            </option>
          ))}
        </select>
      </div>

      <button
        onClick={handleStartNormalization}
        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        disabled={!selectedCity || taskState === 'STARTED'}
      >
        Запустить нормализацию
      </button>

      {taskId && (
        <div className="mt-6">
          <h2 className="text-xl mb-2">Статус задачи: {taskState}</h2>
          <div className="w-full bg-gray-200 rounded-full h-4 mb-4">
            <div
              className="bg-blue-600 h-4 rounded-full"
              style={{ width: `${progress}%` }}
            ></div>
          </div>
          <p>{progress}% завершено</p>
        </div>
      )}

      {error && <div className="mt-4 text-red-500">{error}</div>}
    </div>
  );
};

export default AddressNormalizer;
