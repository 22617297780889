import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';

const colors = [
  'bg-green-50',
  'bg-blue-50',
  'bg-yellow-50',
  'bg-purple-50',
  'bg-pink-50',
  'bg-gray-50',
];

const HistorySection = ({ subjectId }) => {
  const [history, setHistory] = useState([]);
  const [isLoading, setIsLoading] = useState(false); // Изначально не загружаем
  const [hasLoaded, setHasLoaded] = useState(false); // Флаг, чтобы загрузить данные только один раз
  const sectionRef = useRef(null);

  const fetchHistory = async () => {
    setIsLoading(true);
    console.log('Загружены исторические данные');
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/dashboard/api/subject/${subjectId}/history/`,
        {withCredentials: true,}
      );
      
      const filteredHistory = response.data.filter(record => {
        // Включаем записи с непустым history_change_reason или с history_type: "+"
        return record.history_change_reason || record.history_type === "+";
      });
  
      // Добавляем history_change_reason="Создано" для записей с history_type "+"
      const finalHistory = filteredHistory.map(record => {
        if (!record.history_change_reason && record.history_type === "+") {
          return { ...record, history_change_reason: "Создано" };
        }
        return record;
      });
  
      setHistory(finalHistory);
    } catch (error) {
      console.error("Error fetching history:", error);
    } finally {
      setIsLoading(false);
      setHasLoaded(true);
    }
  };
  
  useEffect(() => {
    const observerOptions = {
      root: null, // наблюдать относительно viewport
      rootMargin: '0px',
      threshold: 0.1, // сработает, когда 10% элемента будет видно
    };

    const observerCallback = (entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting && !hasLoaded) {
          fetchHistory();
          observer.unobserve(entry.target); // остановить наблюдение после загрузки
        }
      });
    };

    const observer = new IntersectionObserver(observerCallback, observerOptions);

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    // Очистка при размонтировании
    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, [subjectId, hasLoaded]); // зависимость от subjectId и hasLoaded

  return (
    <div
      ref={sectionRef}
      className="flex flex-col p-4 bg-white border border-gray-200 rounded-lg shadow-md overflow-y-auto flex-1"
      style={{ maxHeight: '100%' }}
    >
      <h3 className="text-lg font-semibold text-gray-700 mb-4">История</h3>
      {isLoading ? (
        <p className="text-sm text-gray-500 text-center">Загрузка...</p>
      ) : hasLoaded && history.length === 0 ? (
        <p className="text-sm text-gray-500 text-center">История отсутствует</p>
      ) : (
        hasLoaded && (
          <div className="space-y-2 flex-1">
            {history.map((record, index) => (
              <div
                key={`${record.id}-${index}`}
                className={`p-3 rounded-md shadow-sm ${colors[index % colors.length]} hover:shadow-md transition-shadow duration-200`}
              >
                <p className="text-xs text-gray-700">
                  <span className="font-semibold">Дата:</span> {new Date(record.history_date).toLocaleString()}
                </p>
                <p className="text-xs text-gray-700">
                  <span className="font-semibold">Цена:</span> {record.price.toLocaleString()} ₽
                </p>
                <p className="text-xs text-gray-700">
                  <span className="font-semibold">Причина:</span> {record.history_change_reason}
                </p>
              </div>
            ))}
          </div>
        )
      )}
    </div>
  );
};

export default HistorySection;
