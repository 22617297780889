// src/components/ProcessManager/tables/ImgAPITable.jsx
import React from "react";
import { Table, Tag, Progress } from "antd";
import { useProcesses } from "../hooks/useProcesses";
import { statusLabels, statusColors } from "../components/statusLabels";


/**
 * Компонент для отображения общего статуса в виде прогресса.
 * Этапы: "Парсинг" -> "Обогащение" -> "Готово".
 * В данном примере рассчитывается процент в зависимости от индекса текущего этапа.
 */
const OverallStatus = ({ currentStage }) => {
  const stages = ["Парсинг", "Обогащение", "Готово"];
  const stageIndex = stages.indexOf(currentStage);
  const percent = stageIndex >= 0 ? ((stageIndex + 1) / stages.length) * 100 : 0;

  return (
    <Progress
      percent={percent}
      steps={stages.length}
      format={() => currentStage || "Нет данных"}
    />
  );
};

/**
 * Компонент таблицы для imgAPI.
 * Принимает пропс `processes` – массив объектов процесса, где ожидаются следующие поля:
 * - start_time: дата и время начала
 * - city: город
 * - task_status: статус задачи (gfhcbyuf)
 * - execution_status: статус выполнения (например, "success", "pending", "failed")
 * - google_sheet_link: ссылка на Google Sheet
 * - object_count: количество объектов
 * - overall_status: текущий этап общего статуса ("Парсинг", "Обогащение", "Готово")
 */
const APITable = ({ user }) => {
  // Фильтруем процессы, оставляя только те, у которых type === "imgAPI"
  const { processes, setProcesses, fetchProcesses } = useProcesses(user);
    // Фильтруем процессы по типу "Вторичка"
    const filteredProcesses = processes.filter(
      (process) => process.type === "imgAPI"
    );  
  
  const columns = [
    {
      title: "Дата и время начала",
      dataIndex: "start_time",
      key: "start_time",
      align: "center",
      render: (text) => {
        if (!text) return "Нет данных";
        const dateObj = new Date(text);
        const dateStr = dateObj.toLocaleDateString();
        const timeStr = dateObj.toLocaleTimeString();
        return (
          <div style={{ fontSize: "12px", lineHeight: "1.2" }}>
            <div>{dateStr}</div>
            <div>{timeStr}</div>
          </div>
        );
      },
    },
    {
      title: "Город",
      dataIndex: "city",
      key: "city",
      align: "center",
    },
    {
          title: "Статус",
          dataIndex: "status",
          key: "status",
          align: "center",
          render: (statusValue) => (
            <Tag color={statusColors[statusValue] || "default"}>
              {statusLabels[statusValue] || "Нет данных"}
            </Tag>
          ),
        },
    {
      title: "Статус выполнения",
      dataIndex: "current_stage",
      key: "current_stage",
      align: "center",
      render: (status) => (
        <Tag
          
        >
          {status || "Нет данных"}
        </Tag>
      ),
    },
    {
      title: "Ссылка на Google Sheet",
      dataIndex: "google_sheet_link",
      key: "google_sheet_link",
      align: "center",
      render: (link) =>
        link ? (
          <a href={link} target="_blank" rel="noopener noreferrer">
            Открыть Sheet
          </a>
        ) : (
          "Нет данных"
        ),
    },
    {
      title: "Количество объектов",
      dataIndex: "updated_objects_count",
      key: "updated_objects_count",
      align: "center",
      render: (count) => (count !== undefined ? count : "0"),
    },
    // {
    //   title: "Общий статус",
    //   dataIndex: "overall_status",
    //   key: "overall_status",
    //   align: "center",
    //   render: (currentStage) => <OverallStatus currentStage={currentStage} />,
    // },
  ];

  return (
    <Table
      columns={columns}
      dataSource={filteredProcesses}
      rowKey="id"
      pagination={{ pageSize: 10 }}
      style={{ width: "100%" }}
    />
  );
};

export default APITable;
