// components/ProfileSelect.jsx
import React from "react";
import { Select } from "antd";
import { SafetyCertificateOutlined, CloseOutlined } from "@ant-design/icons";

const ProfileSelect = ({ record, profiles, handleProfileChange }) => {
  // profile.id или что-то подобное
  const currentProfileId = record?.profile_details?.id;

  return (
    <Select
      style={{ width: 115 }}
      placeholder="Профиль"
      value={currentProfileId}
      onChange={(newVal) => handleProfileChange(record, newVal)}
    >
      {profiles.map((prof) => (
        <Select.Option key={prof.id} value={prof.id}>
          <div style={{ display: "flex", alignItems: "center" }}>
            {prof.description}
            {prof.status === "logged_in" ? (
              <SafetyCertificateOutlined style={{ color: "green", marginLeft: 8 }} />
            ) : (
              <CloseOutlined style={{ color: "red", marginLeft: 8 }} />
            )}
          </div>
        </Select.Option>
      ))}
    </Select>
  );
};

export default ProfileSelect;
