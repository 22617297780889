import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useInView } from 'react-intersection-observer';

const CommentSection = ({ subjectId }) => {
  const [comments, setComments] = useState([]);  
  const [newComment, setNewComment] = useState('');
  const [showComments, setShowComments] = useState(false);
  const [latestComment, setLatestComment] = useState(null);
  const [hasLoaded, setHasLoaded] = useState(false);  // Флаг загрузки комментариев

  // Используем хук useInView из react-intersection-observer
  const { ref, inView } = useInView({
    threshold: 0.1,  // Компонент считается видимым, когда 10% его области видимости
    triggerOnce: true, // Срабатывает только один раз
  });

  // Функция для получения CSRF-токена из cookies
  function getCsrfToken() {
    const cookieValue = document.cookie
      .split('; ')
      .find(row => row.startsWith('csrftoken='))
      ?.split('=')[1];
    return cookieValue;
  }

  useEffect(() => {
    if (inView && !hasLoaded) {
      // Функция для получения комментариев
      const fetchComments = async () => {
        try {
          const response = await axios.get(`${process.env.REACT_APP_API_URL}/dashboard/api/sub/${subjectId}/comments/`, { 
            withCredentials: true,
            headers: {
              'X-CSRFToken': getCsrfToken(),
            },
          });
          const fetchedComments = response.data.results;  // Доступ к комментариям через results

        //   console.log('Fetched comments:', fetchedComments);  // Лог для отладки

          setComments(fetchedComments || []);  // Убедись, что comments всегда массив
          if (fetchedComments.length > 0) {
            setLatestComment(fetchedComments[0]);  // Показываем последний комментарий
          }
          setHasLoaded(true); // Устанавливаем флаг загрузки
        } catch (error) {
          console.error('Error fetching comments:', error);
        }
      };

      fetchComments();
    }
  }, [inView, hasLoaded, subjectId]);

  const handleToggleComments = () => {
    setShowComments((prevShow) => !prevShow);
  };

  const handleAddComment = async () => {
    if (!newComment.trim()) return;

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/dashboard/api/sub/${subjectId}/comments/`, {
        text: newComment,
      }, { 
        withCredentials: true,
        headers: {
          'X-CSRFToken': getCsrfToken(),
        },
      });

      if (Array.isArray(comments)) {
        setComments([response.data, ...comments]); // Добавляем новый комментарий в начало списка
      } else {
        setComments([response.data]); // Если comments не массив, создаем новый массив с одним комментарием
      }

      setLatestComment(response.data); // Обновляем последний комментарий
      setNewComment(''); // Очищаем поле ввода
    } catch (error) {
      console.error('Error adding comment:', error);
    }
  };

  return (
    <div className="comment-section mt-6" ref={ref}>
      {/* Последний комментарий */}
      {latestComment && (
        <div className="latest-comment bg-gray-100 p-4 mb-4 rounded shadow-md">
          <p className="text-sm text-gray-600">{latestComment.created_at}</p>
          <p className="text-blue-800">{latestComment.text}</p>
        </div>
      )}

      {/* Кнопка для переключения видимости комментариев */}
      <button
        onClick={handleToggleComments}
        className="bg-blue-500 text-white px-4 py-2 rounded shadow-md hover:bg-blue-700 transition mb-4"
      >
        {showComments ? 'Скрыть комментарии' : 'Показать комментарии'}
      </button>

      {/* Список комментариев */}
      {showComments && (
        <div className="comments mt-4">
          <div className="comment-list mb-4">
            {comments.length > 0 ? (
              comments.map((comment) => (
                <div key={comment.id} className="comment bg-white p-2 mb-2 border border-gray-200 rounded shadow-sm">
                  {/* Форматируем дату, если поле formatted_created_at отсутствует */}
                  <p className="text-sm text-gray-600">
                    {new Date(comment.created_at).toLocaleDateString('ru-RU', {
                      day: '2-digit',
                      month: '2-digit',
                      year: 'numeric',
                      hour: '2-digit',
                      minute: '2-digit',
                    })}
                  </p>
                  <p>{comment.text}</p>
                </div>
              ))
            ) : (
              <p className="text-gray-500">Комментариев пока нет.</p>
            )}
          </div>

          {/* Добавить новый комментарий */}
          <div className="add-comment mb-4">
            <textarea
              value={newComment}
              onChange={(e) => setNewComment(e.target.value)}
              placeholder="Добавьте ваш комментарий..."
              className="border border-gray-300 w-full p-2 rounded mb-2"
            />
            <button
              onClick={handleAddComment}
              className="bg-green-500 text-white px-4 py-2 rounded shadow-md hover:bg-green-700 transition"
            >
              Добавить комментарий
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default CommentSection;
