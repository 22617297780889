import React, { useMemo, useState, useRef, useCallback } from 'react';
import { YMaps, Map, ObjectManager } from 'react-yandex-maps';
import axios from 'axios';
import { CircularProgress } from '@mui/material';

// ==================== ВСПОМОГАТЕЛЬНЫЕ ФУНКЦИИ ====================

// Группировка объектов по координатам
function groupByCoordinates(subjects) {
  const coordMap = {};
  subjects.forEach((subject) => {
    if (!subject.effective_coordinates) return;
    const [latStr, lonStr] = subject.effective_coordinates.split(' ');
    if (!latStr || !lonStr) return;
    const lat = parseFloat(latStr);
    const lon = parseFloat(lonStr);
    if (Number.isNaN(lat) || Number.isNaN(lon)) return;

    const key = `${lat},${lon}`;
    if (!coordMap[key]) {
      coordMap[key] = [];
    }
    coordMap[key].push(subject);
  });
  return coordMap;
}

// Определяем центр города по его ключу
function getCityCenter(cityKey) {
  switch (cityKey) {
    case 'kirov':
      return [58.603531, 49.668004];
    case 'irkutsk':
      return [52.286387, 104.28066];
    default:
      return [52.286387, 104.28066]; // По умолчанию
  }
}

/**
 * Генерирует HTML-контент балуна с учетом (возможных) расширенных данных.
 * Это эквивалент того, что было в SubjectPlacemarkFancy, но вынесено в функцию.
 */
function buildBalloonContent(groupedSubjects, extendedDataMap) {
  let balloonContent = `
    <div style="padding: 5px; font-family: sans-serif; max-width: 360px;">
  `;

  groupedSubjects.forEach((subject, index) => {
    const isLast = index === groupedSubjects.length - 1;
    const itemBorderStyle = !isLast
      ? 'border-bottom: 1px solid #d1d5db; padding-bottom: 12px; margin-bottom: 12px;'
      : '';

    const statusEmoji = subject.activ ? '✅ Активно' : '❌ Неактивно';

    // Расширенные данные (если уже загружены)
    const extData = extendedDataMap[subject.id];
    const name = (extData && extData.name) || subject.name || 'Без названия';
    const adressValue = (extData && extData.adress) || subject.adress;
    const adress = adressValue ? `📍 ${adressValue}` : '';

    // Состояние фич (например, фото, изменения цены и т.п.)
    const photosValue = extData ? (extData.has_photos ? 1 : 0) : (subject.hasPhotos ? 1 : 0);
    const priceChangeValue = extData
      ? extData.history_counts?.price_change_count
      : subject.priceChangeCount;
    const promotionValue = extData
      ? extData.history_counts?.target_change_count
      : subject.targetChangeCount;
    const duplicatesValue = extData
      ? extData.duplicates_count
      : subject.duplicatesCount;

    const isTruthy = (val) => {
      if (typeof val === 'boolean') return val;
      if (typeof val === 'number') return val > 0;
      if (typeof val === 'string') return val.trim() !== '';
      return false;
    };

    const features = [
      { label: 'Фотографии', value: photosValue, icon: '🖼️' },
      { label: 'Изм. цен', value: priceChangeValue, icon: '💲' },
      { label: 'Продвиж.', value: promotionValue, icon: '⚡' },
      { label: 'Дубликатов', value: duplicatesValue, icon: '🏠' },
    ];

    let featuresHtml = '<div style="display: flex; flex-wrap: wrap; gap: 6px; justify-content: flex-start;">';
    features.forEach((feat) => {
      const truthy = isTruthy(feat.value);
      const checkIcon = truthy
        ? `<span style="color: #10b981; margin-right: 4px;">✔</span>`
        : `<span style="color: #9ca3af; margin-right: 4px;">✖</span>`;
      let displayStr = feat.label;
      if (typeof feat.value === 'number' && !Number.isNaN(feat.value)) {
        displayStr += `: ${feat.value}`;
      }
      featuresHtml += `
        <div style="
          display: flex;
          align-items: center;
          background: #fff;
          border: 1px solid #e5e7eb;
          border-radius: 4px;
          padding: 4px 6px;
          box-shadow: 0 1px 2px rgba(0,0,0,0.05);
          font-size: 12px;
        ">
          ${checkIcon}
          <span>${displayStr}</span>
        </div>
      `;
    });
    featuresHtml += '</div>';

    let mainInfoHtml = `
      <div style="font-weight: 600; color: #1f2937; margin-bottom: 4px;">
        ${name}
      </div>
    `;
    if (adress) {
      mainInfoHtml += `<div style="color: #6b7280; font-size: 14px; margin-bottom: 4px;">${adress}</div>`;
    }
    mainInfoHtml += `<div style="color: #6b7280; font-size: 14px;">${statusEmoji}</div>`;
    mainInfoHtml += `<div style="display: inline-block; margin-top: 4px; margin-bottom: 4px; font-size: 13px; color: rgb(245, 157, 4);">🔗 Есть ссылка</div>`;

    // Собираем финальный блок для этого subject
    balloonContent += `
      <div style="${itemBorderStyle}">
        <!-- Первая строка: фото + основная инфа -->
        <div style="display: flex; margin-bottom: 8px;">
          <div style="flex: 0 0 100px; margin-right: 8px;">
            <img src="https://dummyimage.com/120x120/cccccc/000000.png&text=Фото"
                 alt="Фото"
                 style="width: 100px; height: 100px; object-fit: cover;" />
          </div>
          <div style="flex: 1;">
            ${mainInfoHtml}
          </div>
        </div>
        <!-- Вторая строка: название + features -->
        <div style="width: 100%; margin-top: 8px;">
          <div style="font-weight: bold; margin-bottom: 4px;">Доступная информация в базе:</div>
          ${featuresHtml}
        </div>
      </div>
    `;
  });

  balloonContent += `</div>`;
  return balloonContent;
}

// ==================== ОСНОВНОЙ КОМПОНЕНТ ====================

export default function YandexMapForObjects({
  subjects,
  isCRM,        // влияет на пресеты
  selectedCity, // ключ города, чтобы определить центр
  isLoading,    // флаг, чтобы показывать спиннер
}) {
  // В нем храним расширенные данные по каждому subject.id
  const [extendedDataMap, setExtendedDataMap] = useState({});

  // Группируем по координатам
  const coordMap = useMemo(() => groupByCoordinates(subjects), [subjects]);
  const hasObjects = Object.keys(coordMap).length > 0;

  // Центр карты
  const cityCenter = getCityCenter(selectedCity);

  // Ссылка на объект ObjectManager
  const objectManagerRef = useRef(null);

  // Подгружаем расширенные данные для конкретного subject
  const fetchExtendedDataForSubject = useCallback(
    async (subjectId) => {
      if (extendedDataMap[subjectId]) return; // Уже есть данные

      try {
        const [respExtended, respExtendedAbout] = await Promise.all([
          axios.get(
            `${process.env.REACT_APP_API_URL}/dashboard/api/public/subject/${subjectId}/extended/`
          ),
          axios.get(
            `${process.env.REACT_APP_API_URL}/dashboard/api/public/subject/${subjectId}/extended_about/`
          ),
        ]);
        const mergedData = {
          ...respExtended.data,
          ...respExtendedAbout.data,
        };

        setExtendedDataMap((prev) => ({
          ...prev,
          [subjectId]: mergedData,
        }));
      } catch (error) {
        console.error('Ошибка при загрузке расширенных данных:', error);
      }
    },
    [extendedDataMap]
  );

  /**
   * При клике на балун (у объекта или кластера) грузим расширенные данные
   * для всех сабджектов. После загрузки — обновляем balloonContent.
   */
  const onBalloonOpen = useCallback(
    async (e) => {
      if (!objectManagerRef.current) return;

      const type = e.get('type');
      // Если кликнули на одиночный объект
      if (type === 'object') {
        const objectId = e.get('objectId');
        const objectData = objectManagerRef.current.objects.getById(objectId);
        if (!objectData || !objectData.properties) return;

        const { groupedSubjects } = objectData.properties;
        if (!groupedSubjects) return;

        // Грузим расширенные данные для всех subject в этой группе
        await Promise.all(
          groupedSubjects.map((s) => fetchExtendedDataForSubject(s.id))
        );

        // После того, как всё загрузили, пересобираем balloonContent
        const newContent = buildBalloonContent(groupedSubjects, extendedDataMap);

        // Обновляем у ObjectManager контент балуна
        objectManagerRef.current.objects.setObjectProperties(objectId, {
          balloonContent: newContent,
        });

        // Если балун уже открыт — перерисуем его
        objectManagerRef.current.objects.balloon.open(objectId);
      }
      // Если кликнули на кластер
      else if (type === 'cluster') {
        const clusterId = e.get('clusterId');
        const clusterData = objectManagerRef.current.clusters.getById(clusterId);
        if (!clusterData || !clusterData.features) return;

        // Внутри кластера может быть несколько фич
        for (const feature of clusterData.features) {
          const groupedSubjects = feature.properties.groupedSubjects || [];
          // Подгружаем extendedData
          await Promise.all(
            groupedSubjects.map((s) => fetchExtendedDataForSubject(s.id))
          );

          // Пересобираем balloonContent для каждой фичи
          const newContent = buildBalloonContent(groupedSubjects, extendedDataMap);

          // Обновляем свойства
          objectManagerRef.current.objects.setObjectProperties(feature.id, {
            balloonContent: newContent,
          });
        }

        // Перерисуем сам кластер
        objectManagerRef.current.clusters.balloon.open(clusterId);
      }
    },
    [fetchExtendedDataForSubject, extendedDataMap]
  );

  // Формируем "фичи" для ObjectManager
  const features = useMemo(() => {
    return Object.entries(coordMap).map(([key, groupedSubjects]) => {
      const [lat, lon] = key.split(',').map(Number);
      // Проверяем, есть ли в группе хоть один активный
      const hasActive = groupedSubjects.some((s) => s.activ);
      // Выбираем иконку (или цвет) метки
      const defaultPreset = hasActive
        ? 'islands#blueCircleIcon'
        : 'islands#grayCircleIcon';

      // Генерируем начальное содержимое балуна (без гарантии, что extendedData уже подгружено)
      const balloonContent = buildBalloonContent(groupedSubjects, extendedDataMap);

      return {
        type: 'Feature',
        id: key,
        geometry: {
          type: 'Point',
          coordinates: [lat, lon],
        },
        options: {
          // Если нужна разная иконка для CRM
          preset: isCRM ? 'islands#redCircleIcon' : defaultPreset,
          iconImageSize: [24, 24],
        },
        properties: {
          groupedSubjects,
          // Подсказка при наведении
          hintContent:
            groupedSubjects.length > 1
              ? `Группа объектов (${groupedSubjects.length})`
              : groupedSubjects[0]?.name || 'Объект',
          // Начальный контент балуна
          balloonContent,
        },
      };
    });
  }, [coordMap, extendedDataMap, isCRM]);

  // Объединяем фичи в коллекцию
  const objectManagerData = useMemo(
    () => ({
      type: 'FeatureCollection',
      features,
    }),
    [features]
  );

  return (
    <div style={{ position: 'relative', width: '100%', height: '600px', marginTop: '16px' }}>
      {isLoading && (
        <div
          style={{
            position: 'absolute',
            inset: 0,
            zIndex: 9999,
            backgroundColor: 'rgba(255, 255, 255, 0.7)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CircularProgress />
        </div>
      )}

      <YMaps
        query={{
          apikey: '0e4c41b7-8d62-4e16-a940-1b397927d2f8', // Замените на свой ключ
          lang: 'ru_RU',
          load: 'package.full',
        }}
      >
        <Map
          defaultState={{
            center: cityCenter,
            zoom: 10,
          }}
          width="100%"
          height="100%"
        >
          {hasObjects && (
            <ObjectManager
              options={{
                clusterize: true,
                gridSize: 64,
              }}
              objects={{
                openBalloonOnClick: true,
              }}
              clusters={{
                preset: 'islands#invertedVioletClusterIcons',
                openBalloonOnClick: true,
              }}
              features={objectManagerData}
              instanceRef={(ref) => {
                if (ref) {
                  objectManagerRef.current = ref;
                  // Подписываемся на событие открытия балуна у одиночных объектов
                  ref.objects.events.add('balloonopen', onBalloonOpen);
                  // И на событие открытия балуна у кластеров
                  ref.clusters.events.add('balloonopen', onBalloonOpen);
                }
              }}
            />
          )}
        </Map>
      </YMaps>
    </div>
  );
}
