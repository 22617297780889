import React from 'react';

export default function FooterSection() {
  return (
    <footer className="w-full bg-blue-900 py-6 text-white">
      <div className="container mx-auto px-4 flex flex-col md:flex-row justify-between items-center">
        <div className="mb-4 md:mb-0">
          <h4 className="font-bold text-xl">SOCL24</h4>
          <p className="text-gray-300">© {new Date().getFullYear()}. Все права защищены.</p>
        </div>
        <div>
          <p className="text-gray-300">Контакты: +7 999 123-45-67 | info@socl24.ru</p>
        </div>
      </div>
    </footer>
  );
}
