// src/components/StartProcess.js
import React, { useState } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { Button, Select, message, Form, Checkbox, Card } from "antd";

const { Option } = Select;

const StartNewsParser = ({ user }) => {
  const [profiles, setProfiles] = useState([]);
  const [selectedProfile, setSelectedProfile] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [loadingProfiles, setLoadingProfiles] = useState(false);
  const [headless, setHeadless] = useState(true);

  const fetchProfiles = () => {
    setLoadingProfiles(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/profiles/`, {
        withCredentials: true,
      })
      .then((response) => {
        setProfiles(response.data);
      })
      .catch((error) => {
        console.error("Ошибка загрузки профилей:", error);
        message.error("Не удалось загрузить профили.");
      })
      .finally(() => {
        setLoadingProfiles(false);
      });
  };

  const handleStartProcess = () => {
    if (!user) {
      message.warning("Сначала войдите в систему (пользователь не аутентифицирован).");
      return;
    }

    if (!selectedProfile) {
      message.warning("Выберите профиль GoLogin для запуска процесса.");
      return;
    }

    setIsLoading(true);

    axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/start-news-parser/`,
        {
          profile_id: selectedProfile,
          headless: headless,
        },
        {
          headers: {
            "X-CSRFToken": Cookies.get("csrftoken") || "",
          },
          withCredentials: true,
        }
      )
      .then((response) => {
        message.success(response.data.message);
      })
      .catch((error) => {
        if (error.response) {
          const { status, data } = error.response;
          console.error("Ошибка при запуске процесса:", status, data);
          if (data && data.error) {
            message.error(`Ошибка: ${data.error}`);
          } else {
            message.error("Ошибка при запуске процесса. Код " + status);
          }
        } else {
          message.error("Ошибка при запуске процесса (нет ответа).");
          console.error(error);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const renderProfileOption = (profile) => {
    const statusLabel = profile.status === "logged_in" ? "Залогинен" : "Незалогинен";
    const usageLabel = profile.usage === "free" ? "Свободен" : "Занят";
    const description = profile.description || "";
    return (
      <Option key={profile.id} value={profile.id}>
        {`${description} (${statusLabel}, ${usageLabel})`}
      </Option>
    );
  };

  return (
    <div style={{ display: "flex", justifyContent: "center", margin: "20px" }}>
      <Card title="Запуск новостного парсера" style={{ width: 500 }}>
        <Form layout="vertical">
          <Form.Item label="Выберите профиль GoLogin">
            <Select
              placeholder="Выберите профиль GoLogin"
              value={selectedProfile}
              onChange={setSelectedProfile}
              loading={loadingProfiles}
              onDropdownVisibleChange={(open) => {
                if (open) {
                  fetchProfiles();
                }
              }}
            >
              {profiles.map((profile) => renderProfileOption(profile))}
            </Select>
          </Form.Item>
          <Form.Item>
            <Checkbox
              checked={headless}
              onChange={(e) => setHeadless(e.target.checked)}
            >
              Headless
            </Checkbox>
          </Form.Item>
          <Form.Item>
            <Button type="primary" onClick={handleStartProcess} loading={isLoading} block>
              Запустить процесс
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

export default StartNewsParser;
