// components/LogsButtons.jsx
import React from "react";
import { Tooltip } from "antd";
import { PlusOutlined, MinusOutlined } from "@ant-design/icons";

const LogsButtons = ({ record, expandedLogs, toggleLog }) => {
  // Если нет файлов логов
  if (!record.stdout_log_file && !record.stderr_log_file) {
    return (
      <div
        style={{
          marginLeft: 8,
          width: 10,
          height: 10,
          borderRadius: "50%",
          background: "red",
        }}
      />
    );
  }

  return (
    <>
      {/* Кнопка stdout */}
      {expandedLogs[record.id]?.stdout ? (
        <Tooltip title="Свернуть stdout">
          <MinusOutlined
            style={{ marginLeft: 8, cursor: "pointer", fontSize: 16, color: "green" }}
            onClick={() => toggleLog(record, "stdout")}
          />
        </Tooltip>
      ) : (
        <Tooltip title="Показать stdout">
          <PlusOutlined
            style={{ marginLeft: 8, cursor: "pointer", fontSize: 16, color: "green" }}
            onClick={() => toggleLog(record, "stdout")}
          />
        </Tooltip>
      )}
      {/* Кнопка stderr */}
      {expandedLogs[record.id]?.stderr ? (
        <Tooltip title="Свернуть stderr">
          <MinusOutlined
            style={{ marginLeft: 8, cursor: "pointer", fontSize: 16, color: "green" }}
            onClick={() => toggleLog(record, "stderr")}
          />
        </Tooltip>
      ) : (
        <Tooltip title="Показать stderr">
          <PlusOutlined
            style={{ marginLeft: 8, cursor: "pointer", fontSize: 16, color: "green" }}
            onClick={() => toggleLog(record, "stderr")}
          />
        </Tooltip>
      )}
    </>
  );
};

export default LogsButtons;
